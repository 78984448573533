import React, { useState, useEffect } from 'react';

function CreateDestinoForm({ onSubmit }) {
    const [turs, setTurs] = useState([]);
    const [formData, setFormData] = useState({
        idTur: '',
        img: null,
    });

    useEffect(() => {
        // Hacer una solicitud GET al servidor para obtener la lista de turs
        fetch('https://aga.gst-panel.com/api_destinos_v.php')
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setTurs(data);
                } else {
                    console.error('La respuesta del servidor está vacía.');
                }
            })
            .catch((error) => {
                console.error('Error al obtener la lista de turs:', error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('idTur', formData.idTur);
        formDataToSend.append('img', formData.img);

        // Imprimir FormData para verificar su contenido
        for (var pair of formDataToSend.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // Realiza una solicitud POST al servidor para agregar destino
        fetch('https://aga.gst-panel.com/api_destinos.php', {
            method: 'POST',
            body: formDataToSend,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    console.log('Destino agregado con éxito:', response);
                } else {
                    console.error('Error al agregar destino:', response.error);
                }
            })
            .catch((error) => {
                console.error('Error al agregar destino:', error);
            });
    };

    return (
        <div className="create-form">
            <h2>Crear Destino</h2>
            <form onSubmit={handleSubmit}>
                <select name="idTur" onChange={handleChange}>
                    <option value="">Seleccionar Tur</option>
                    {turs.map((tur) => (
                        <option key={tur.Id} value={tur.Id}>
                            {tur.DescripcionE}
                        </option>
                    ))}
                </select>
                <input type="file" name="img" accept="image/*" onChange={handleChange} />
                <button type="submit">Guardar Destino</button>
            </form>
        </div>
    );
}

export default CreateDestinoForm;
