import React, { useEffect, useState } from 'react';
import './Admin.css'; // Import the CSS file

import CreateForm from './CreateForm';

function Administracion() {
    const [userRole, setUserRole] = useState(0);
    const [isCreateTourVisible, setCreateTourVisible] = useState(false);
    const [countries, setCountries] = useState([]);
    const [continentes, setContinentes] = useState([]);
    const [newCountry, setNewCountry] = useState({ descripcion: '' });
    const [isCreateCountryVisible, setCreateCountryVisible] = useState(false);

    useEffect(() => {
        // Hacer una solicitud GET al servidor para obtener la lista de países
        fetch('https://aga.gst-panel.com/api_tours.php?action=paises')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Error en la solicitud');
                }
                return response.json();
            })
            .then((data) => {
                if (data) {
                    setCountries(data);
                } else {
                    console.error('La respuesta del servidor está vacía.');
                }
            })
            .catch((error) => {
                console.error('Error al obtener la lista de países:', error);
            });

        // Resto de tu código para verificar el rol del usuario
    }, []);
    useEffect(() => {
        // Hacer una solicitud GET al servidor para obtener la lista de países
        fetch('https://aga.gst-panel.com/api_tours.php?action=continentes')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Error en la solicitud');
                }
                return response.json();
            })
            .then((data) => {
                if (data) {
                    setContinentes(data);
                } else {
                    console.error('La respuesta del servidor está vacía.');
                }
            })
            .catch((error) => {
                console.error('Error al obtener la lista de países:', error);
            });

        // Resto de tu código para verificar el rol del usuario
    }, []);

    const handleCreateCountryClick = () => {
        setCreateCountryVisible(true);
    };

    const handleCreateCountry = (data) => {
        console.log("la data es esta ", data);
        // Hacer una solicitud POST al servidor para crear un país
        fetch('https://aga.gst-panel.com/api_tours.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `action=crearPais&descripcion=${data.descripcion}&continente=${data.continente}`,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Error en la solicitud');
                }
                return response.json();
            })
            .then((response) => {
                if (response.success) {
                    console.log('País guardado con éxito:', data.descripcion);
                    setCreateCountryVisible(false);

                    // Actualiza la lista de países
                    setCountries([...countries, { Id: response.Id, Descripcion: data.descripcion }]);
                } else {
                    console.error('Error al guardar el país:', response.error);
                }
            })
            .catch((error) => {
                console.error('Error al guardar el país:', error);
            });
    };

    const handleCreateTourClick = () => {
        setCreateTourVisible(true);
    };

    const handleCreateTour = (data) => {
        // Realiza una solicitud POST al servidor para crear un tour
        fetch('https://aga.gst-panel.com/api_tours.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `action=crearTour&paisId=${data.pais}&agencia=${data.agencia}&valor=${data.valor}&descripcionE=${data.descripcionE}&descripcionI=${data.descripcionI}`,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Error en la solicitud');
                }
                return response.json();
            })
            .then((response) => {
                if (response.success) {
                    console.log('Tour guardado con éxito:', data);
                    setCreateTourVisible(false);
                } else {
                    console.error('Error al guardar el tour:', response.error);
                }
            })
            .catch((error) => {
                console.error('Error al guardar el tour:', error);
            });
    };

    return (
        <div>
            <h1>Página de Administración</h1>
            <p>Bienvenido, administrador.</p>

            <button onClick={handleCreateCountryClick}>Crear País</button>

            {isCreateCountryVisible && (
                <div>
                    <CreateForm
                        title="Crear País"
                        onSubmit={(data) => {
                            handleCreateCountry(data);
                        }}
                        onClose={() => setCreateCountryVisible(false)}
                        countries={continentes}
                        formType="country" // Indica que es un formulario para crear país
                    />
                </div>
            )}

            <button onClick={handleCreateTourClick}>Crear Tour</button>

            {isCreateTourVisible && (
                <div>
                    <CreateForm
                        title="Crear Tour"
                        onSubmit={(data) => {
                            handleCreateTour(data);
                        }}
                        onClose={() => setCreateTourVisible(false)}
                        countries={countries}
                        formType="tour" // Indica que es un formulario para crear tour
                    />
                </div>
            )}
        </div>
    );
}

export default Administracion;
