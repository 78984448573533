import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';  // Importa useParams
import Menu from '../../components/Menu/Menunosotros';
import './Ofertas.css';
import { FaWhatsapp } from 'react-icons/fa';

import Logo from "../../Img/Logo_1.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import Calendar from 'react-calendar';
import iconperson from "../../Img/travel-icon-11.png";
import iconpersonni from "../../Img/nino.png";
import 'react-calendar/dist/Calendar.css';
import Fireworks from '../../components/Fireworks/Fireworks';
import redes from '../../Img/Barraredessociales.svg';
import whatsaap from '../../Img/Redes/wpp.svg';
import instagram from '../../Img/Redes/ig.svg';
import facebook from '../../Img/Redes/fb.svg';



function Ofta() {
    const [destinos, setDestinos] = useState([]);
    const [selectedDestinoInfo, setSelectedDestinoInfo] = useState({});
    const [selectedDestinoInfoa, setSelectedDestinoInfoa] = useState({
        cantidadAdultos: 1

    });
    const [selectedDestinoInfom, setSelectedDestinoInfom] = useState({ cantidadMenores: 0 })


    const { id } = useParams();  // Obtiene el parámetro Id de la URL
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [showForm, setShowForm] = useState(false);

    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');
    const modalRef = useRef(null);
    const initialCantidadPersonas = selectedDestinoInfo?.cantidadPersonas || 1;

    // Nuevo estado para gestionar la vista actual del modal
    const [modalView, setModalView] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date()); // Nuevo estado para la fecha seleccionada

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const countryId = searchParams.get('countryId');
    const [showFireworks, setShowFireworks] = useState(true);
    const isDateSelectable = (date) => {
        const currentDate = new Date();
        const minSelectableDate = new Date(currentDate.getTime() + 4 * 24 * 60 * 60 * 1000); // 4 días después de la fecha actual
        return date >= minSelectableDate;
    };

    useEffect(() => {
        fetch(`https://aga.gst-panel.com/ap_ofertas.php?countryId=${id}`) // Usa el parámetro Id en la URL
            .then((response) => response.json())
            .then((data) => {
                setDestinos(data);
                const fireworksTimeout = setTimeout(() => {
                    setShowFireworks(true);
                }, 100000);
                return () => clearTimeout(fireworksTimeout);
            })
            .catch((error) => {
                console.error('Error al obtener datos de la API:', error);
            });
    }, [id]);


    // useEffect(() => {


    //     if (countryId) {
    //         fetch(`https://aga.gst-panel.com/ap_ofertas.php`)
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 setDestinos(data);
    //                 const fireworksTimeout = setTimeout(() => {
    //                     setShowFireworks(true);
    //                 }, 100000); // Duración de 10 segundos
    //                 return () => clearTimeout(fireworksTimeout);
    //             })
    //             .catch((error) => {
    //                 console.error('Error al obtener datos de la API:', error);
    //             });
    //     } else {
    //         fetch('https://aga.gst-panel.com/ap_ofertas.php')

    //             .then((response) => response.json())
    //             .then((data) => {
    //                 setDestinos(data);
    //                 const fireworksTimeout = setTimeout(() => {
    //                     setShowFireworks(true);
    //                 }, 100000); // Duración de 10 segundos
    //                 return () => clearTimeout(fireworksTimeout);
    //             })
    //             .catch((error) => {
    //                 console.error('Error al obtener datos de la API:', error);
    //             });
    //     }
    // }, [countryId]);

    const handleCardClick = (Id_tur, DestinoId, Valor, DescripcionI) => {
        fetch(`https://aga.gst-panel.com/get_infod.php?id_destino=${Id_tur}`)
            .then((response) => response.json())
            .then((infodData) => {
                // Actualiza el estado con los datos recibidos
                setSelectedDestinoInfo({
                    itinerario: infodData.itinerario,
                    incluye: infodData.incluye,
                    noincluye: infodData.noincluye,
                    adicionales: infodData.adicionales,
                    itinerarioi: infodData.itinerarioi,
                    incluyei: infodData.incluyei,
                    noincluyei: infodData.noincluyei,
                    adicionalesi: infodData.adicionalesi,
                    Valor: Valor,
                    DescripcionI: DescripcionI,
                    DestinoId,
                });

                showModal();
            })
            .catch((error) => {
                console.error('Error al obtener datos de infod:', error);
            });
    };

    const showModal = () => {
        if (modalRef.current) {
            modalRef.current.style.display = 'block';
        }
    };

    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.style.display = 'none';
        }
    };

    const handleCompraClick = () => {
        setModalView('Compra');
    };


    const handlePreguntar = () => {
        const cantidadPersonas = selectedDestinoInfo.cantidadPersonas !== undefined ? selectedDestinoInfo.cantidadPersonas : 1;

        // Verifica si selectedStartDate es una instancia válida de Date
        const formattedStartDate = selectedStartDate instanceof Date ? selectedStartDate.toLocaleDateString() : 'Fecha de inicio no seleccionada';

        // Verifica si selectedEndDate es una instancia válida de Date
        const formattedEndDate = selectedEndDate instanceof Date ? selectedEndDate.toLocaleDateString() : 'Fecha de fin no seleccionada';

        const mensajeWhatsApp = `Hola, estoy interesado en el tour N° ${selectedDestinoInfo.DestinoId} "${selectedDestinoInfo.DescripcionI}" para ${cantidadPersonas} personas. ¿Podrías proporcionarme más información sobre la disponibilidad y el precio desde ${formattedStartDate} hasta ${formattedEndDate}?`;

        const linkWhatsApp = `https://wa.me/+16892864404/?text=${encodeURIComponent(mensajeWhatsApp)}`;
        window.location.href = linkWhatsApp;
    };



    const handleApartar = () => {
        // Implementa la lógica para redirigir a PayPal
        const precioTotal = selectedDestinoInfo.Valor * selectedDestinoInfo.cantidadPersonas;
        const linkPayPal = `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=TUCORREO%40EJEMPLO.COM&currency_code=USD&amount=${precioTotal}&item_name=Reserva%20de%20tour%20a%20${selectedDestinoInfo.DestinoId}&return=TUPAGINA%2FCOMPRA%2FSUCCESS&cancel_return=TUPAGINA%2FCOMPRA%2FCANCEL`;
        window.location.href = linkPayPal;
    };
    console.log(destinos);

    const handleDateChange = (date) => {
        if (!selectedStartDate) {
            setSelectedStartDate(date);
            setSelectedEndDate(null); // Reset end date if start date is selected again
        } else if (!selectedEndDate) {
            if (date > selectedStartDate) {
                setSelectedEndDate(date);
            } else {
                setSelectedEndDate(selectedStartDate);
                setSelectedStartDate(date);
            }
        } else {
            // If both dates are selected, reset the selection
            setSelectedStartDate(date);
            setSelectedEndDate(null);
        }
    };
    const handleWhatsAppClick = () => {
        // Mostrar el formulario al hacer clic en el botón
        setShowForm(true);
    };

    const handleFormSubmit = () => {
        // Construir el mensaje con la información del formulario
        const mensajeWhatsApp = `Hola, mi nombre es ${nombre} y mi correo es ${correo}. ${mensaje}`;

        // Crear la URL de WhatsApp con el mensaje prellenado
        const linkWhatsApp = `https://wa.me/16892864404?text=${encodeURIComponent(mensajeWhatsApp)}`;

        // Abrir una nueva ventana para iniciar el chat de WhatsApp
        window.open(linkWhatsApp, '_blank');

        // Ocultar el formulario después de enviar el mensaje
        setShowForm(false);
    };

    return (

        <div>
            {showFireworks && <Fireworks onFinish={() => setShowFireworks(false)} />}

            <div>
                <Menu />
                <div className="logo-and-svg-containers">
                    <a href="#/"> <img src={Logo} alt="Logo" className="logo-img" /> </a>
                </div>
            </div>

            <div className="ofertas">
                <br></br><br></br>
                <br></br><br></br>
                <center> <h1 className="ofertas-title">OFERTAS </h1> </center>

                <div className="row">
                    {destinos.map((destino, index) => (
                        <div className="col-md-4 mb-4" key={index} onClick={() => handleCardClick(destino.Id_tur, destino.DestinoId, destino.Valor, destino.DescripcionI)}>
                            <div
                                className="cardofertas"
                                style={{
                                    backgroundImage: `url(https://aga.gst-panel.com/imgoferta/${destino.Imagen})`
                                }}
                            >
                                <div className="cardofertas-text">
                                    <h5 className="cardofertas-title">{destino.DescripcionI}</h5>
                                    <h6>{destino.NombrePais.charAt(0).toUpperCase() + destino.NombrePais.slice(1)}</h6>
                                    <h1 className="Title-cash">${destino.Valor}</h1>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div ref={modalRef} className="modal-container">
                <div className="modal-content">
                    <span className="close-button" onClick={hideModal}>
                        &times;
                    </span>
                    <h2>Información del Destino</h2>
                    <div className='h2info'> Información del Destino
                    </div>

                    {/* Nueva sección con opciones */}
                    <div className="modal-options">

                        <div
                            className={`modal-option ${modalView === 'Compra' ? 'active' : ''}`}
                            onClick={() => setModalView('Compra')}
                        >
                            <div className='h1modal'>  PREGUNTA AQUI</div>
                        </div>
                        <div
                            className={`modal-option ${modalView === 'Itinerario' ? 'active' : ''}`}
                            onClick={() => setModalView('Itinerario')}
                        >
                            <div className='h1modal'>  Itinerario</div>

                        </div>






                        <div
                            className={`modal-option ${modalView === 'Incluye' ? 'active' : ''}`}
                            onClick={() => setModalView('Incluye')}
                        >
                            <div className='h1modal'>  Incluye</div>
                        </div>
                        <div
                            className={`modal-option ${modalView === 'NoIncluye' ? 'active' : ''}`}
                            onClick={() => setModalView('NoIncluye')}
                        >
                            <div className='h1modal'>  No Incluye</div>
                        </div>
                        <div
                            className={`modal-option ${modalView === 'Adicionales' ? 'active' : ''}`}
                            onClick={() => setModalView('Adicionales')}
                        >
                            <div className='h1modal'> Adicionales</div>
                        </div>
                    </div>

                    {/* Contenido dinámico según la vista seleccionada */}


                    {modalView === 'Compra' && (
                        <>
                            <h3 className="white-text">Comprar  {selectedDestinoInfo.DescripcionI}</h3>
                            <div className="slider-container">
                                <p>
                                    <label htmlFor="cantidadAdultos">Cantidad de adultos:</label>
                                </p>
                                <div className="person-icon">
                                    <img src={iconperson} alt="Person Icon" />
                                    <input
                                        type="number"
                                        id="cantidadAdultos"
                                        min="1"
                                        max="10"
                                        value={Number(selectedDestinoInfoa.cantidadAdultos) !== undefined ? Number(selectedDestinoInfoa.cantidadAdultos) : 1}

                                        onChange={(e) =>
                                            setSelectedDestinoInfoa((prev) => ({ ...prev, cantidadAdultos: e.target.value }))
                                        }
                                    />
                                </div>
                                <p>
                                    <label htmlFor="cantidadMenores">Cantidad de menores:</label>
                                </p>
                                <div className="person-icon">
                                    <img src={iconpersonni} alt="Person Icon" />
                                    <input
                                        type="number"
                                        id="cantidadMenores"
                                        min="0"
                                        max="10"
                                        value={Number(selectedDestinoInfom.cantidadMenores)}

                                        onChange={(e) =>
                                            setSelectedDestinoInfom((prev) => ({ ...prev, cantidadMenores: e.target.value }))
                                        }
                                    />
                                </div>
                                <p>
                                    <span>Total: {Number(selectedDestinoInfoa.cantidadAdultos) + Number(selectedDestinoInfom.cantidadMenores)}</span>
                                </p>
                            </div>
                            <div className="precio-total-section">
                                <p>
                                    <label htmlFor="precioTotal">Precio total:</label>
                                </p>
                                <div className="price-icon">
                                    <p>
                                        <h1 className="ofertas-title">      <span>{`$${Number(selectedDestinoInfo.Valor) * (Number(selectedDestinoInfoa.cantidadAdultos) + Number(selectedDestinoInfom.cantidadMenores)) || selectedDestinoInfo.Valor} `}</span> </h1>
                                    </p>
                                </div>
                            </div>
                            <div className="modal-calendar">
                                <div className="mobile-view">
                                    <div className="modal-buttons">
                                        <button onClick={handlePreguntar}>Preguntar (WhatsApp)</button>
                                        <button onClick={handleApartar}>Apartar (PayPal)</button>
                                    </div>
                                    <Calendar
                                        onChange={handleDateChange}
                                        selectRange={true}
                                        value={[selectedStartDate, selectedEndDate]}
                                        tileDisabled={({ date }) => !isDateSelectable(date)}
                                    />
                                </div>
                            </div>

                        </>
                    )}



                    {modalView === 'Itinerario' && (
                        <>
                            <h3 className="white-text">Itinerario del Destino</h3>
                            <div style={{ whiteSpace: 'pre-line', color: 'white' }}>
                                {selectedDestinoInfo.itinerario}
                            </div>
                        </>
                    )}
                    {modalView === 'Incluye' && (
                        <>
                            <h3 className="white-text">Lo que Incluye el Destino</h3>
                            <ul>
                                <p className="white-text">{selectedDestinoInfo.incluye}</p>

                                {/* Agrega más elementos según sea necesario */}
                            </ul>
                        </>
                    )}
                    {modalView === 'NoIncluye' && (
                        <>
                            <h3 className="white-text">Lo que No Incluye el Destino</h3>
                            <ul>
                                <p className="white-text">{selectedDestinoInfo.noincluye}</p>

                                {/* Agrega más elementos según sea necesario */}
                            </ul>
                        </>
                    )}
                    {modalView === 'Adicionales' && (
                        <>
                            <h3 className="white-text">Servicios Adicionales Disponibles</h3>
                            <ul>
                                <p className="white-text">{selectedDestinoInfo.adicionales}</p>

                                {/* Agrega más elementos según sea necesario */}
                            </ul>
                        </>
                    )}
                    {/* Calendario en la parte central derecha */}

                </div>
            </div>
            <div className="Redes-and-svg-container">
                <img
                    src={redes}
                    alt="Mi SVG"
                    className="svg-image"
                    style={{ width: '65px', height: '500px' }} // Ajusta el tamaño aquí
                />

            </div>
            <div className="Redes-whatsaap-and-svg-container">
                <a href="https://api.whatsapp.com/send?phone=16892864404&text=🖐%EF%B8%8FHola%20!%20quiero%20saber%20más%20!" target="_blank" rel="noopener noreferrer">
                    <img
                        src={whatsaap}
                        alt="WhatsApp"
                        className="svg-image"
                        style={{ width: '45px', height: '480px' }} // Ajusta el tamaño aquí
                    />
                </a>
            </div>
            <div className="Redes-instagram-and-svg-container">
                <a href="https://instagram.com/agatours_promociones?igshid=MWZjMTM2ODFkZg==" target="_blank" rel="noopener noreferrer">
                    <img
                        src={instagram}
                        alt="Instagram"
                        className="svg-image"
                        style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                    />
                </a>
            </div>
            <div>

                <div className="Redes-facebook-and-svg-container">
                    <a href="URL_DEL_FACEBOOK" target="_blank" rel="noopener noreferrer">
                        <img
                            src={facebook}
                            alt="https://www.facebook.com/agatourspromociones"
                            className="svg-image"
                            style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                        />
                    </a>
                </div>

                <button className="whatsapp-button" onClick={handleWhatsAppClick}>
                    {/* Tu icono o texto del botón... */}<FaWhatsapp size={30} color="#fff" />
                </button>

                {/* Formulario flotante */}
                {showForm && (
                    <div className="formulario-whatsapp">
                        <span className="close-button" onClick={() => setShowForm(false)}>
                            &times;
                        </span>
                        <h2>Comunicate con nosotros</h2>
                        <label>Nombre:</label>
                        <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                        <label>Correo:</label>
                        <input type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} required />
                        <label>Mensaje:</label>
                        <textarea value={mensaje} onChange={(e) => setMensaje(e.target.value)} required />
                        <button onClick={handleFormSubmit}>Enviar a WhatsApp</button>
                    </div>
                )}
            </div>


        </div >

    );
}

export default Ofta;
