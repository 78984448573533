import React, { Component } from 'react';

class ApiCaller extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ciudades: [],
        };
    }

    componentDidMount() {
        fetch('http://localhost/api/api.php', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                this.setState({ ciudades: data });
            })
            .catch((error) => {
                console.error('Error al obtener datos de la API:', error);
            });
    }

    render() {
        const { ciudades } = this.state;

        return (
            <div>
                {this.props.children(ciudades)}
            </div>
        );
    }
}

export default ApiCaller;
