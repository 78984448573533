import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menunosotros';
import './Apertura.css';
import 'bootstrap/dist/css/bootstrap.css';
import Logo from "../../Img/Logo_1.png";
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import redes from '../../Img/Barraredessociales.svg';
import whatsaap from '../../Img/Redes/wpp.svg';
import instagram from '../../Img/Redes/ig.svg';
import facebook from '../../Img/Redes/fb.svg';

function Apertura() {
    const [destinos, setDestinos] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');


    useEffect(() => {
        fetch('https://aga.gst-panel.com/ap.php')
            .then((response) => response.json())
            .then((data) => {
                setDestinos(data);
            })
            .catch((error) => {
                console.error('Error al obtener datos de la API:', error);
            });
    }, []);

    const handleDownloadExcel = () => {
        // Crea un enlace temporal
        const link = document.createElement('a');
        link.href = '/fronteras.xls';  // Ruta al archivo Excel

        link.download = 'fronteras.xlsx';  // Nombre del archivo a descargar
        document.body.appendChild(link);

        // Simula un clic en el enlace para iniciar la descarga
        link.click();

        // Limpia el enlace temporal
        document.body.removeChild(link);
        console.log("este es el archivo ")
    };

    const handleWhatsAppClick = () => {
        // Mostrar el formulario al hacer clic en el botón
        setShowForm(true);
    };

    const handleFormSubmit = () => {
        // Construir el mensaje con la información del formulario
        const mensajeWhatsApp = `Hola, mi nombre es ${nombre} y mi correo es ${correo}. ${mensaje}`;

        // Crear la URL de WhatsApp con el mensaje prellenado
        const linkWhatsApp = `https://wa.me/16892864404?text=${encodeURIComponent(mensajeWhatsApp)}`;

        // Abrir una nueva ventana para iniciar el chat de WhatsApp
        window.open(linkWhatsApp, '_blank');

        // Ocultar el formulario después de enviar el mensaje
        setShowForm(false);
    };



    return (
        <div>

            <Menu />

            <div className="logo-and-svg-containers">
                <img src={Logo} alt="Logo" className="logo-img" />
            </div>

            <div className="container">
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <h1 className="mt-5 mb-4">Apertura De Fronteras</h1>

                <div className="row">
                    {/* Botón de descarga de Excel */}
                    <button className="btn btn-primary" onClick={handleDownloadExcel}>
                        Descargar Excel
                    </button>



                </div>
                <div className="Redes-and-svg-container">
                    <img
                        src={redes}
                        alt="Mi SVG"
                        className="svg-image"
                        style={{ width: '65px', height: '500px' }} // Ajusta el tamaño aquí
                    />

                </div>
                <div className="Redes-whatsaap-and-svg-container">
                    <a href="https://api.whatsapp.com/send?phone=16892864404&text=🖐%EF%B8%8FHola%20!%20quiero%20saber%20más%20!" target="_blank" rel="noopener noreferrer">
                        <img
                            src={whatsaap}
                            alt="WhatsApp"
                            className="svg-image"
                            style={{ width: '45px', height: '480px' }} // Ajusta el tamaño aquí
                        />
                    </a>
                </div>
                <div className="Redes-instagram-and-svg-container">
                    <a href="https://instagram.com/agatours_promociones?igshid=MWZjMTM2ODFkZg==" target="_blank" rel="noopener noreferrer">
                        <img
                            src={instagram}
                            alt="Instagram"
                            className="svg-image"
                            style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                        />
                    </a>
                </div>
                <div>

                    <div className="Redes-facebook-and-svg-container">
                        <a href="URL_DEL_FACEBOOK" target="_blank" rel="noopener noreferrer">
                            <img
                                src={facebook}
                                alt="https://www.facebook.com/agatourspromociones"
                                className="svg-image"
                                style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                            />
                        </a>
                    </div>
                </div>
            </div>


            <button className="whatsapp-button" onClick={handleWhatsAppClick}>
                {/* Tu icono o texto del botón... */}<FaWhatsapp size={30} color="#fff" />
            </button>

            {/* Formulario flotante */}
            {showForm && (
                <div className="formulario-whatsapp">
                    <span className="close-button" onClick={() => setShowForm(false)}>
                        &times;
                    </span>
                    <h2>Comunicate con nosotros</h2>
                    <label>Nombre:</label>
                    <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                    <label>Correo:</label>
                    <input type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} required />
                    <label>Mensaje:</label>
                    <textarea value={mensaje} onChange={(e) => setMensaje(e.target.value)} required />
                    <button onClick={handleFormSubmit}>Enviar a WhatsApp</button>
                </div>
            )}
            {/* <Link to="https://api.whatsapp.com/send?phone=16892864404&text=🖐%EF%B8%8FHola%20!%20quiero%20saber%20más%20!" target="_blank" className="whatsapp-button">
                <FaWhatsapp size={30} color="#fff" />
            </Link> */}
        </div>
    );
}

export default Apertura;
