import React, { Component } from 'react';
import { Link } from 'react-router-dom'; // Importa Link desde react-router-dom
import { withRouter } from 'react-router-dom';

import './Menu.css';
import Lineas from "../../Img/Menu1.svg";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuActive: false,
            continents: [],
            countries: [],
            searchValue: '',
            searchResults: [],
            applySlider: window.innerWidth < 768, // Inicialmente, verifica si el slider debe aplicarse
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ applySlider: window.innerWidth < 768 });
    };

    toggleMenu = () => {
        this.setState((prevState) => ({
            menuActive: !prevState.menuActive,
        }));
    };

    handleResultClick = (countryId) => {
        // Utiliza la prop history para redirigir al usuario a la página de Destinos con el countryId seleccionado
        this.props.history.push(`/Destinos?countryId=${countryId}`);
    };


    loadContinents = async () => {
        try {
            const response = await fetch('https://aga.gst-panel.com/api_tours.php?action=continentes');
            if (!response.ok) {
                throw new Error('Error al cargar continentes');
            }
            const data = await response.json();
            this.setState({ continents: data, countries: [] }); // Reinicia countries al cargar continentes
        } catch (error) {
            console.error('Error:', error);
        }
    };
    // Nueva función para cerrar el menú
    closeMenu = () => {
        this.setState({ menuActive: false });
    };
    loadCountriesByContinent = async (continentId) => {
        try {
            const response = await fetch('https://aga.gst-panel.com/api_tours.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `action=paisesPorContinente&continente=${continentId}`,
            });

            if (!response.ok) {
                throw new Error('Error al cargar países por continente');
            }

            const data = await response.json();
            this.setState({ countries: data }); // Actualiza solo los países, no cierra el menú
        } catch (error) {
            console.error('Error:', error);
        }
    };

    handleResultClick = (countryId) => {
        // Cierra el menú al hacer clic en un resultado
        this.closeMenu();

        // Utiliza la prop history para redirigir al usuario a la página de Destinos con el countryId seleccionado
        this.props.history.push(`/Destinos?countryId=${countryId}`);
    };

    loadCountriesBySearch = async (searchValue) => {
        try {
            const response = await fetch('https://aga.gst-panel.com/busqueda.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `action=paisesPorBusqueda&busqueda=${searchValue}`,
            });

            if (!response.ok) {
                throw new Error('Error al cargar países por búsqueda');
            }

            const data = await response.json();
            this.setState({ searchResults: data }); // Actualiza los resultados de búsqueda
        } catch (error) {
            console.error('Error:', error);
        }
    };

    handleSearchChange = (e) => {
        const searchValue = e.target.value;
        this.setState({ searchValue });

        // Verifica si searchValue está vacío, en cuyo caso no realiza la búsqueda
        if (searchValue.trim() === '') {
            this.setState({ searchResults: [] });
            return;
        }

        // Llama a la función de búsqueda con un retraso para evitar llamadas excesivas mientras el usuario escribe
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.loadCountriesBySearch(searchValue);
        }, 300);
    };

    // Agrega esta función al componente para manejar la redirección al hacer clic en un resultado de búsqueda
    handleResultClick = (countryId) => {
        // Utiliza la prop history para redirigir al usuario a la página de Destinos con el countryId seleccionado
        this.props.history.push(`/Destinos?countryId=${countryId}`);
    };
    render() {
        const { menuActive, continents, countries, searchValue, searchResults, applySlider } = this.state;
        const { currentColor } = this.props;

        const sliderSettings = {
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <>            <div className="menu">

                <div>
                    <div className="menu-icon" onClick={this.toggleMenu}>
                        <div className="lineas-svg">
                            <img src={Lineas} alt="Lineas" width="40" height="40" />
                        </div>
                    </div>
                    <div className={`menu-items ${menuActive ? 'active' : ''}`}>
                        <div>
                            {applySlider ? (
                                // En dispositivos móviles, redirige directamente a la página de destinos
                                <a href="#/Destinocel">
                                    DESTINOS
                                </a>
                            ) : (
                                // En pantallas de PC, muestra el menú desplegable de destinos
                                <>
                                    <a href="#" onClick={this.loadContinents}>
                                        DESTINOS
                                    </a>
                                    <div className="destinos-dropdownn">
                                        {continents.map((continent) => (
                                            <div key={continent.Id} onClick={() => this.loadCountriesByContinent(continent.Id)}>
                                                {continent.Descripcion}
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                        <div>
                            <a href="#/Ofertas">OFERTAS</a>

                            {applySlider ? (
                                <div className="destinos-dropdown">
                                    {searchResults.length > 0 && (
                                        <Slider {...sliderSettings}>
                                            {searchResults.map((result) => (
                                                <div key={result.Id} className="letras">
                                                    <Link to={`/Destinos?countryId=${result.Id}`}>
                                                        {result.Descripcion}
                                                    </Link>
                                                </div>
                                            ))}
                                        </Slider>
                                    )}
                                </div>
                            ) : (
                                <div className={`destinos-dropdown ${countries.length !== 0 ? 'active' : ''}`}>
                                    {Array.from({ length: Math.ceil(countries.length / 5) }, (v, i) => i * 5).map(
                                        (startIndex) => (
                                            <div className="letras-row" key={startIndex}>
                                                {countries.slice(startIndex, startIndex + 5).map((country) => (
                                                    <div className="letras" key={country.Id}>
                                                        <Link to={`/Destinos?countryId=${country.Id}`}>
                                                            {country.Descripcion}
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                        <a href="#/Apertura">APERTURA DE FRONTERAS</a>
                        <a href="#/nosotros">¿ QUIENES SOMOS ? </a>
                        <div className="search-box">
                            <div className="search-input">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    style={{
                                        height: '30px',
                                        width: '250px',
                                        borderRadius: '20px',
                                        borderColor: 'transparent'
                                    }}
                                    value={searchValue}
                                    onChange={this.handleSearchChange}
                                />
                            </div>


                            {searchResults.length > 0 && (
                                <div className="search-results">
                                    {searchResults.map((result) => (
                                        <div
                                            key={result.Id}
                                            className="search-result-item"
                                        >
                                            <Link to={`/Destinos?countryId=${result.Id}`}>
                                                {result.Descripcion}
                                            </Link>

                                        </div>
                                    ))}
                                </div>
                            )}

                        </div>

                    </div>
                </div>

                <div>

                </div>
            </div>


            </>

        );
    }
}

export default Menu;
