import React, { useState, useEffect, useRef } from 'react';
import Menu from '../../components/Menu/Menunosotros';
import './Destinos.css';
import Logo from "../../Img/Logo_1.png";
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Calendar from 'react-calendar';
import iconperson from "../../Img/travel-icon-11.png";
import 'react-calendar/dist/Calendar.css';
import redes from '../../Img/Barraredessociales.svg';
import whatsaap from '../../Img/Redes/wpp.svg';
import instagram from '../../Img/Redes/ig.svg';
import facebook from '../../Img/Redes/fb.svg';
import iconpersonni from "../../Img/nino.png";

function Destinos() {
    const [destinos, setDestinos] = useState([]);
    const [selectedDestinoInfo, setSelectedDestinoInfo] = useState(null);
    const modalRef = useRef(null);
    const initialCantidadPersonas = selectedDestinoInfo?.cantidadPersonas || 1;
    const [selectedDestinoInfoa, setSelectedDestinoInfoa] = useState({
        cantidadAdultos: 1

    });
    const [selectedDestinoInfom, setSelectedDestinoInfom] = useState({ cantidadMenores: 0 })


    // Nuevo estado para gestionar la vista actual del modal
    const [modalView, setModalView] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date()); // Nuevo estado para la fecha seleccionada

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const countryId = searchParams.get('countryId');

    const handleCardClick = (DestinoId, Valor, DescripcionI) => {
        fetch(`https://aga.gst-panel.com/get_infod.php?id_destino=${DestinoId}`)
            .then((response) => response.json())
            .then((infodData) => {
                // Actualiza el estado con los datos recibidos
                setSelectedDestinoInfo({
                    itinerario: infodData.itinerario,
                    incluye: infodData.incluye,
                    noincluye: infodData.noincluye,
                    adicionales: infodData.adicionales,
                    itinerarioi: infodData.itinerarioi,
                    incluyei: infodData.incluyei,
                    noincluyei: infodData.noincluyei,
                    adicionalesi: infodData.adicionalesi,
                    Valor: Valor,
                    DescripcionI: DescripcionI,
                    DestinoId,
                });
                showModal();
            })
            .catch((error) => {
                console.error('Error al obtener datos de infod:', error);
            });
    };

    const showModal = () => {
        if (modalRef.current) {
            modalRef.current.style.display = 'block';
        }
    };

    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.style.display = 'none';
        }
    };

    useEffect(() => {
        if (countryId) {
            fetch(`https://aga.gst-panel.com/ap.php?action=destinosPorPais&countryId=${countryId}`)
                .then((response) => response.json())
                .then((data) => {
                    setDestinos(data);
                })
                .catch((error) => {
                    console.error('Error al obtener datos de la API:', error);
                });
        } else {
            fetch('https://aga.gst-panel.com/ap.php')
                .then((response) => response.json())
                .then((data) => {
                    setDestinos(data);
                })
                .catch((error) => {
                    console.error('Error al obtener datos de la API:', error);
                });
        }
    }, [countryId]);


    const handleCompraClick = () => {
        setModalView('Compra');
    };



    const handlePreguntar = () => {
        // Verifica si selectedDestinoInfo.cantidadPersonas está undefined y asigna 1 en su lugar
        const cantidadPersonas = selectedDestinoInfo.cantidadPersonas !== undefined ? selectedDestinoInfo.cantidadPersonas : 1;

        // Implementa la lógica para enviar un mensaje a WhatsApp con la información
        const mensajeWhatsApp = `Hola, estoy interesado en el tour N° ${selectedDestinoInfo.DestinoId} "${selectedDestinoInfo.DescripcionI}" para ${cantidadPersonas
            } personas. ¿Podrías proporcionarme más información sobre la disponibilidad y el precio para la fecha ${selectedDate.toLocaleDateString()}?`;

        const linkWhatsApp = `https://wa.me/+14078006570/?text=${encodeURIComponent(mensajeWhatsApp)}`;
        window.location.href = linkWhatsApp;
    };


    const handleApartar = () => {
        // Implementa la lógica para redirigir a PayPal
        const precioTotal = selectedDestinoInfo.Valor * selectedDestinoInfo.cantidadPersonas;
        const linkPayPal = `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=TUCORREO%40EJEMPLO.COM&currency_code=USD&amount=${precioTotal}&item_name=Reserva%20de%20tour%20a%20${selectedDestinoInfo.DestinoId}&return=TUPAGINA%2FCOMPRA%2FSUCCESS&cancel_return=TUPAGINA%2FCOMPRA%2FCANCEL`;
        window.location.href = linkPayPal;
    };
    console.log(destinos);
    return (
        <div>
            <div>
                <Menu />
                <div className="logo-and-svg-containers">
                    <a href="#/"> <img src={Logo} alt="Logo" className="logo-img" /> </a>
                </div>


            </div>

            <div className="container">


                <br></br><br></br><br></br>    <center> <h1 className="mt-5 mb-4">Nuestros Destinos</h1> </center>

                <div className="row">
                    {destinos.map((destino, index) => (
                        <div className="col-md-4 mb-4" key={index} onClick={() => handleCardClick(destino.DestinoId, destino.Valor, destino.DescripcionI)}>
                            <div
                                className="carddestinos"
                                style={{
                                    backgroundImage: `url(https://aga.gst-panel.com/img/${destino.Imagen})`
                                }}
                            >
                                <div className="carddestinos-text">
                                    <h5 className="carddestinos-title">{destino.DescripcionI}</h5>
                                    <h6>{destino.NombrePais.charAt(0).toUpperCase() + destino.NombrePais.slice(1)}</h6>
                                    <h4 className="Title-cash">${destino.Valor}</h4>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div ref={modalRef} className="modal-container">
                <div className="modal-content">
                    <span className="close-button" onClick={hideModal}>
                        &times;
                    </span>
                    <h2>Información del Destino</h2>
                    <div className='h2info'> Información del Destino
                    </div>

                    {/* Nueva sección con opciones */}
                    <div className="modal-options">

                        <div
                            className={`modal-option ${modalView === 'Compra' ? 'active' : ''}`}
                            onClick={() => setModalView('Compra')}
                        >
                            <div className='h1modal'>  PREGUNTA AQUI</div>
                        </div>
                        <div
                            className={`modal-option ${modalView === 'Itinerario' ? 'active' : ''}`}
                            onClick={() => setModalView('Itinerario')}
                        >
                            <div className='h1modal'>  Itinerario</div>

                        </div>






                        <div
                            className={`modal-option ${modalView === 'Incluye' ? 'active' : ''}`}
                            onClick={() => setModalView('Incluye')}
                        >
                            <div className='h1modal'>  Incluye</div>
                        </div>
                        <div
                            className={`modal-option ${modalView === 'NoIncluye' ? 'active' : ''}`}
                            onClick={() => setModalView('NoIncluye')}
                        >
                            <div className='h1modal'>  No Incluye</div>
                        </div>
                        <div
                            className={`modal-option ${modalView === 'Adicionales' ? 'active' : ''}`}
                            onClick={() => setModalView('Adicionales')}
                        >
                            <div className='h1modal'> Adicionales</div>
                        </div>
                    </div>

                    {/* Contenido dinámico según la vista seleccionada */}

                    {modalView === 'Compra' && (
                        <>
                            <h3 className="white-text">Comprar {selectedDestinoInfo.DescripcionI}</h3>
                            <div className="slider-container">
                                <p>
                                    <label htmlFor="cantidadAdultos">Cantidad de adultos:</label>
                                </p>
                                <div className="person-icon">
                                    <img src={iconperson} alt="Person Icon" />
                                    <input
                                        type="number"
                                        id="cantidadAdultos"
                                        min="1"
                                        max="10"
                                        value={Number(selectedDestinoInfoa.cantidadAdultos) !== undefined ? Number(selectedDestinoInfoa.cantidadAdultos) : 1}

                                        onChange={(e) =>
                                            setSelectedDestinoInfoa((prev) => ({ ...prev, cantidadAdultos: e.target.value }))
                                        }
                                    />
                                </div>
                                <p>
                                    <label htmlFor="cantidadMenores">Cantidad de menores:</label>
                                </p>
                                <div className="person-icon">
                                    <img src={iconpersonni} alt="Person Icon" />
                                    <input
                                        type="number"
                                        id="cantidadMenores"
                                        min="0"
                                        max="10"
                                        value={Number(selectedDestinoInfom.cantidadMenores)}

                                        onChange={(e) =>
                                            setSelectedDestinoInfom((prev) => ({ ...prev, cantidadMenores: e.target.value }))
                                        }
                                    />
                                </div>
                                <p>
                                    <span>Total: {Number(selectedDestinoInfoa.cantidadAdultos) + Number(selectedDestinoInfom.cantidadMenores)}</span>
                                </p>
                            </div>
                            <div className="precio-total-section">
                                <p>
                                    <label htmlFor="precioTotal">Precio total:</label>
                                </p>
                                <div className="price-icon">
                                    <p>
                                        <h1 className="ofertas-title">      <span>{`$${Number(selectedDestinoInfo.Valor) * (Number(selectedDestinoInfoa.cantidadAdultos) + Number(selectedDestinoInfom.cantidadMenores)) || selectedDestinoInfo.Valor} `}</span> </h1>
                                    </p>
                                </div>
                            </div>
                            <center>
                                <div className="modal-calendar">
                                    <div className="modal-buttons">
                                        <button onClick={handlePreguntar}>Preguntar (WhatsApp)</button>
                                        <button onClick={handleApartar}>Apartar (PayPal)</button>
                                    </div>
                                    <Calendar onChange={setSelectedDate} value={selectedDate} />
                                </div>
                            </center>
                        </>
                    )}




                    {modalView === 'Itinerario' && (
                        <>
                            <h3 className="white-text">Itinerario del Destino</h3>
                            <div style={{ whiteSpace: 'pre-line', color: 'white' }}>
                                {selectedDestinoInfo.itinerario}
                            </div>
                        </>
                    )}
                    {modalView === 'Incluye' && (
                        <>
                            <h3 className="white-text">Lo que Incluye el Destino</h3>
                            <ul>
                                <p className="white-text">{selectedDestinoInfo.incluye}</p>

                                {/* Agrega más elementos según sea necesario */}
                            </ul>
                        </>
                    )}
                    {modalView === 'NoIncluye' && (
                        <>
                            <h3 className="white-text">Lo que No Incluye el Destino</h3>
                            <ul>
                                <p className="white-text">{selectedDestinoInfo.noincluye}</p>

                                {/* Agrega más elementos según sea necesario */}
                            </ul>
                        </>
                    )}
                    {modalView === 'Adicionales' && (
                        <>
                            <h3 className="white-text">Servicios Adicionales Disponibles</h3>
                            <ul>
                                <p className="white-text">{selectedDestinoInfo.adicionales}</p>

                                {/* Agrega más elementos según sea necesario */}
                            </ul>
                        </>
                    )}
                    {/* Calendario en la parte central derecha */}

                </div>
            </div>
            <div className="Redes-and-svg-container">
                <img
                    src={redes}
                    alt="Mi SVG"
                    className="svg-image"
                    style={{ width: '65px', height: '500px' }} // Ajusta el tamaño aquí
                />

            </div>
            <div className="Redes-whatsaap-and-svg-container">
                <a href="https://api.whatsapp.com/send?phone=16892864404&text=🖐%EF%B8%8FHola%20!%20quiero%20saber%20más%20!" target="_blank" rel="noopener noreferrer">
                    <img
                        src={whatsaap}
                        alt="WhatsApp"
                        className="svg-image"
                        style={{ width: '45px', height: '480px' }} // Ajusta el tamaño aquí
                    />
                </a>
            </div>
            <div className="Redes-instagram-and-svg-container">
                <a href="https://instagram.com/agatours_promociones?igshid=MWZjMTM2ODFkZg==" target="_blank" rel="noopener noreferrer">
                    <img
                        src={instagram}
                        alt="Instagram"
                        className="svg-image"
                        style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                    />
                </a>
            </div>
            <div>

                <div className="Redes-facebook-and-svg-container">
                    <a href="URL_DEL_FACEBOOK" target="_blank" rel="noopener noreferrer">
                        <img
                            src={facebook}
                            alt="https://www.facebook.com/agatourspromociones"
                            className="svg-image"
                            style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                        />
                    </a>
                </div>
            </div>
        </div >

    );
}

export default Destinos;
