import React, { useState, useEffect } from 'react';

function CrearOfertas({ onSubmitDestino, onSubmitOferta }) {
    const [turs, setTurs] = useState([]);
    const [formDataDestino, setFormDataDestino] = useState({
        idTur: '',
        img: null,
    });
    const [formDataOferta, setFormDataOferta] = useState({
        idTur: '',
        valorOferta: '',
        imgOferta: null,
        fechaFinOferta: '', // Nuevo campo para la fecha de fin de oferta
    });

    useEffect(() => {
        fetch('https://aga.gst-panel.com/api_destinos_v.php')
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setTurs(data);
                } else {
                    console.error('La respuesta del servidor está vacía.');
                }
            })
            .catch((error) => {
                console.error('Error al obtener la lista de turs:', error);
            });
    }, []);


    const handleChangeOferta = (e) => {
        const { name, value, type, files } = e.target;
        setFormDataOferta((prevData) => ({
            ...prevData,
            [name]: type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmitOferta = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('idTur', formDataOferta.idTur);
        formDataToSend.append('valorOferta', formDataOferta.valorOferta);
        formDataToSend.append('imgOferta', formDataOferta.imgOferta);
        formDataToSend.append('fechaFinOferta', formDataOferta.fechaFinOferta); // Agregar la fecha de fin de oferta

        fetch('https://aga.gst-panel.com/api_ofertas.php', {
            method: 'POST',
            body: formDataToSend,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    console.log('Oferta agregada con éxito:', response);
                    // Lógica adicional después de agregar la oferta, si es necesario
                } else {
                    console.error('Error al agregar oferta:', response.error);
                }
            })
            .catch((error) => {
                console.error('Error al agregar oferta:', error);
            });
    };

    return (
        <div className="create-forms">

            <div className="create-form">
                <h2>Crear Oferta</h2>
                <form onSubmit={handleSubmitOferta}>
                    <select name="idTur" onChange={handleChangeOferta}>
                        <option value="">Seleccionar Tur</option>
                        {turs.map((tur) => (
                            <option key={tur.Id} value={tur.Id}>
                                {tur.DescripcionE}
                            </option>
                        ))}
                    </select>
                    <input type="number" name="valorOferta" placeholder="Valor de la oferta" onChange={handleChangeOferta} />
                    <input type="file" name="imgOferta" accept="image/*" onChange={handleChangeOferta} />
                    <input type="date" name="fechaFinOferta" onChange={handleChangeOferta} /> {/* Nuevo campo de fecha */}
                    <button type="submit">Guardar Oferta</button>
                </form>
            </div>
        </div>
    );
}

export default CrearOfertas;
