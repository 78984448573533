import React from 'react';
import { useSpring, animated } from 'react-spring';

const Fireworks = ({ onFinish }) => {
    const { opacity } = useSpring({
        opacity: 0,
        from: { opacity: 1 },
        reset: true,
        onRest: onFinish,
    });

    return (
        <animated.div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'black',
                opacity,
                backgroundImage: `url(https://aga.gst-panel.com/imgoferta/FESTIVAL.jpg)`,
                backgroundSize: 'cover', // Ajusta según tus necesidades
                backgroundPosition: 'center', // Ajusta según tus necesidades
            }}
        >
            {/* Aquí puedes agregar imágenes o elementos SVG que representen los fuegos artificiales */}
            {/* Por ejemplo, podrías usar imágenes de fuegos artificiales o incluso SVG animados */}
            {/* <svg>...</svg> */}
        </animated.div>
    );
};

export default Fireworks;
