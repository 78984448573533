import React, { useState } from 'react';
import './Login.css'; // Import the CSS file

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    // const history = useHistory(); // Crea una instancia de useHistory

    const handleLogin = (e) => {
        e.preventDefault();
        setMessage('Ingreso ');

        fetch('https://aga.gst-panel.com/api_login.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "Usuario": username,
                "Clave": password,
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (!data.error) {
                    if (data.role === 1993) {
                        document.cookie = `userRole=${data.role}; path=/`;

                        setMessage('Usted es el administrador.');
                        window.location.href = '/#/administracion';

                        // history.push('/administracion'); // Redirige al usuario a la página de administración
                    } else if (data.role === 2) {
                        setMessage('Usted es un empleado.');
                        // Redirige al empleado a su página correspondiente
                    } else {
                        setMessage('Rol desconocido. Redirección pendiente.', data.message);
                        // Redirige a otras páginas según los roles
                    }
                    // Limpiar los campos después de un inicio de sesión exitoso
                    setUsername('');
                    setPassword('');
                } else {
                    setMessage(data.error);
                    console.error('Error de inicio de sesión:', data.error);
                }
            })
            .catch(error => {
                console.error('Error al iniciar sesión:', error);
                setMessage(error);
            });
    }

    return (
        <div>
            <center>
                <form>
                    <div>
                        <h1>Iniciar Sesión</h1>
                        <form>
                            <label>Nombre de Usuario:
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </label>
                            <br />
                            <label>Contraseña:
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </label>
                            <br />
                            <button onClick={handleLogin}>Iniciar Sesión</button>
                        </form>
                        {message && <div className="error-message">{message}</div>}

                        <h2>{message} </h2>
                    </div>            </form>
            </center>
        </div>
    );
}

export default Login;
