import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';  // Importa Link

import Menu from '../components/Menu/Menunosotros';
import { FaWhatsapp } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';  // Importa useParams

// import './Destinocel.css';
import Logo from "../Img/Logo_1.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import Calendar from 'react-calendar';
import iconperson from "../Img/travel-icon-11.png";
import iconpersonni from "../Img/nino.png";
import 'react-calendar/dist/Calendar.css';
import Fireworks from '../components/Fireworks/Fireworks';
import redes from '../Img/Barraredessociales.svg';
import whatsaap from '../Img/Redes/wpp.svg';
import instagram from '../Img/Redes/ig.svg';
import facebook from '../Img/Redes/fb.svg';



function Destinopais() {
    const [destinos, setDestinos] = useState([]);
    const [selectedDestinoInfo, setSelectedDestinoInfo] = useState({});
    const [selectedDestinoInfoa, setSelectedDestinoInfoa] = useState({
        cantidadAdultos: 1

    });
    const [selectedDestinoInfom, setSelectedDestinoInfom] = useState({ cantidadMenores: 0 })


    const { id } = useParams();  // Obtiene el parámetro Id de la URL
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [showForm, setShowForm] = useState(false);

    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');
    const modalRef = useRef(null);
    const initialCantidadPersonas = selectedDestinoInfo?.cantidadPersonas || 1;

    // Nuevo estado para gestionar la vista actual del modal
    const [modalView, setModalView] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date()); // Nuevo estado para la fecha seleccionada

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const countryId = searchParams.get('countryId');
    const [showFireworks, setShowFireworks] = useState(true);
    const isDateSelectable = (date) => {
        const currentDate = new Date();
        const minSelectableDate = new Date(currentDate.getTime() + 4 * 24 * 60 * 60 * 1000); // 4 días después de la fecha actual
        return date >= minSelectableDate;
    };


    useEffect(() => {
        console.log("este es el id ", id);
        fetch(`https://aga.gst-panel.com/appaisescel.php?continentId=${id}`)

            .then((response) => response.json())
            .then((data) => {
                setDestinos(data);
                const fireworksTimeout = setTimeout(() => {
                    setShowFireworks(true);
                }, 100000); // Duración de 10 segundos
                return () => clearTimeout(fireworksTimeout);
            })
            .catch((error) => {
                console.error('Error al obtener datos de la API:', error);
            });

    }, [id]);


    console.log(destinos)

    const handleFormSubmit = () => {
        // Construir el mensaje con la información del formulario
        const mensajeWhatsApp = `Hola, mi nombre es ${nombre} y mi correo es ${correo}. ${mensaje}`;

        // Crear la URL de WhatsApp con el mensaje prellenado
        const linkWhatsApp = `https://wa.me/16892864404?text=${encodeURIComponent(mensajeWhatsApp)}`;

        // Abrir una nueva ventana para iniciar el chat de WhatsApp
        window.open(linkWhatsApp, '_blank');

        // Ocultar el formulario después de enviar el mensaje
        setShowForm(false);
    };



    const handleWhatsAppClick = () => {
        // Mostrar el formulario al hacer clic en el botón
        setShowForm(true);
    };

    return (

        <div>
            {showFireworks && <Fireworks onFinish={() => setShowFireworks(false)} />}

            <div>
                <Menu />
                <div className="logo-and-svg-containers">
                    <a href="#/"> <img src={Logo} alt="Logo" className="logo-img" /> </a>
                </div>
            </div>

            <div className="ofertas">
                <br></br><br></br>
                <br></br><br></br>
                <center> <h1 className="ofertas-title">PAIS</h1> </center>

                <div className="row">
                    {destinos.map((destino) => (
                        <div className="col-md-4 mb-4" key={destino.IdContinentes}>

                            <Link to={`/Destinos?countryId=${destino.PaisId}`}>

                                <div
                                    className="cardofertas"
                                    style={{
                                        backgroundImage: `url(https://aga.gst-panel.com/img/${destino.Imagen})`
                                    }}
                                >
                                    <div className="cardofertas-text">
                                        <h2>{destino.Nombre}</h2>
                                        {/* Agrega más información según tus necesidades */}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>


            <div className="Redes-and-svg-container">
                <img
                    src={redes}
                    alt="Mi SVG"
                    className="svg-image"
                    style={{ width: '65px', height: '500px' }} // Ajusta el tamaño aquí
                />

            </div>
            <div className="Redes-whatsaap-and-svg-container">
                <a href="https://api.whatsapp.com/send?phone=16892864404&text=🖐%EF%B8%8FHola%20!%20quiero%20saber%20más%20!" target="_blank" rel="noopener noreferrer">
                    <img
                        src={whatsaap}
                        alt="WhatsApp"
                        className="svg-image"
                        style={{ width: '45px', height: '480px' }} // Ajusta el tamaño aquí
                    />
                </a>
            </div>
            <div className="Redes-instagram-and-svg-container">
                <a href="https://instagram.com/agatours_promociones?igshid=MWZjMTM2ODFkZg==" target="_blank" rel="noopener noreferrer">
                    <img
                        src={instagram}
                        alt="Instagram"
                        className="svg-image"
                        style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                    />
                </a>
            </div>
            <div>

                <div className="Redes-facebook-and-svg-container">
                    <a href="URL_DEL_FACEBOOK" target="_blank" rel="noopener noreferrer">
                        <img
                            src={facebook}
                            alt="https://www.facebook.com/agatourspromociones"
                            className="svg-image"
                            style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                        />
                    </a>
                </div>
            </div>

            <button className="whatsapp-button" onClick={handleWhatsAppClick}>
                {/* Tu icono o texto del botón... */}

                <FaWhatsapp size={30} color="#fff" />
            </button>

            {/* Formulario flotante */}
            {showForm && (
                <div className="formulario-whatsapp">
                    <span className="close-button" onClick={() => setShowForm(false)}>
                        &times;
                    </span>
                    <h2>Comunicate con nosotros</h2>
                    <label>Nombre:</label>
                    <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                    <label>Correo:</label>
                    <input type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} required />
                    <label>Mensaje:</label>
                    <textarea value={mensaje} onChange={(e) => setMensaje(e.target.value)} required />
                    <button onClick={handleFormSubmit}>Enviar a WhatsApp</button>
                </div>
            )}
        </div >

    );
}

export default Destinopais;
