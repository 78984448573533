import React from 'react';

const NextArrow = ({ onClick }) => {
    return (
        <div className="arrow next" onClick={onClick}>
            {/* Aquí puedes usar un icono o cualquier otro contenido para representar la flecha hacia adelante */}
        </div>
    );
};

export default NextArrow;
