import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import imgS from "../Img/11.jpg";
import img1 from "../Img/14.jpg";
import img2 from "../Img/13.jpg";
import img3 from "../Img/12.jpg";



import NextArrow from "./NextArrow"; // Importa la flecha hacia adelante
import PrevArrow from "./PrevArrow"; // Importa la flecha hacia atrás
import './Banner.css'; // Importa el archivo CSS
import fondazul from "../Img/azul.png";
import fondazulp from "../Img/texto azulpeq.png";
import fondrosa from "../Img/Rosa.png";
import fondverd from "../Img/Verde.png";
import Menu from "./Menu/Menu";
import Buscar from "../Img/Buscar rosa.svg";
import Logo from "../Img/Logo_1.png";
import CustomSVG from "./CustomSVG";
import Textpink from "../Img/Textos/Texto grande rosa.png";
import Textblu from "../Img/Textos/texto azul.png";
import Textgren from "../Img/Textos/Texto grande rosa.png";
import redes from "../Img/Redes/Barra redes sociales.svg";
import whatsaap from "../Img/Redes/wpp.svg";
import instagram from "../Img/Redes/ig.svg";
import facebook from "../Img/Redes/fb.svg";
import youtube from "../Img/Redes/ytb.svg";
import avion from "../Img/avion.svg";
import eeuu from "../Img/eeuu.jpg";
import ApiCaller from './ApiCaller'; // Ajusta la ruta de importación
import RegistroInicioSesionForm from "../page/Login/Login"; // Importar el formulario de registro/inicio de sesión



class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {

            images: [imgS, img1, img2, img3],
            colors: ['#39A6DF', '#39A6DF', '#72B650', '#fe62b1'],
            currentIndex: 0,
            backgrounds: [fondazul, fondazul, fondverd, fondrosa], // Nuevos fondos de pantalla
            Textchange: [Textblu, Textpink, Textblu, Textgren], // Nuevos TExtos de pantalla
        };
    }

    componentDidMount() {
        this.interval = setInterval(this.nextSlide, 3000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    prevSlide = () => {
        this.setState((prevState) => ({
            currentIndex: (prevState.currentIndex - 1 + prevState.images.length) % prevState.images.length,
        }));
    };

    nextSlide = () => {
        this.setState((prevState) => ({
            currentIndex: (prevState.currentIndex + 1) % prevState.images.length,
        }));
    };
    // Método para manejar el clic en CustomSVG
    handleCustomSVGClick = () => {
        this.setState({ showRegistroInicioSesionForm: true });
    };

    render() {
        const { images, colors, currentIndex, backgrounds, Textchange } = this.state;
        const currentImage = images[currentIndex];
        const currentColor = colors[currentIndex];
        const currentBackground = backgrounds[currentIndex];
        const TextChang = Textchange[currentIndex];

        const bannerStyle = {
            backgroundImage: `url(${currentImage})`,
            backgroundColor: currentColor,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            height: '700px',
            position: 'relative',
        };

        const fondazulStyle = {
            position: 'absolute',

            top: 0,
            left: -20,
            width: '70%', // La mitad de la pantalla
            height: '100%',
            zIndex: 1,
            backgroundImage: `url(${currentBackground})`, // Cambia el fondo dinámicamente
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        };


        const textoir = {
            position: 'absolute',

            top: 0,
            left: -20,
            width: '70%', // La mitad de la pantalla
            height: '100%',
            zIndex: 3,
            backgroundImage: `url(${TextChang})`, // Cambia el fondo dinámicamente
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        };



        // Estilo para el botón "Descubre"
        const descubreButtonStyle = {
            zIndex: 5,
            position: 'absolute',
            top: 550,
            left: 100,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Color blanco transparentoso
            color: 'white',
            fontSize: '18px',
            width: '200px', // Ancho del botón
            height: '40px', // Alto del botón
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px', // Bordes redondeados

            // Nuevos estilos específicos para pantallas más pequeñas
            '@media (max-width: 767px)': {
                width: '100%', // Ancho del botón al 100%
                height: '40px', // Alto del botón
                marginBottom: '10px', // Espacio entre los botones
            },
        };
        // Estilo para el botón "Conoce mas"
        const conoceMasButtonStyle = {
            zIndex: 5,
            position: 'absolute',
            top: 550,
            left: 350,
            border: '2px solid white', // Bordes de color blanco
            color: 'white',
            fontSize: '18px',
            width: '200px', // Ancho del botón
            height: '40px', // Alto del botón
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px', // Bordes redondeados
            marginLeft: '10px', // Espacio entre botones

            // Nuevos estilos específicos para pantallas más pequeñas
            '@media (max-width: 767px)': {
                width: '100%', // Ancho del botón al 100%
                height: '40px', // Alto del botón
                marginBottom: '10px', // Espacio entre los botones
            },
        };
        const vuelta = {
            zIndex: 1,
            position: 'absolute',
            top: 650,
            left: 300,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Color blanco transparentoso
            color: 'white',
            fontSize: '18px',
            width: '200px', // Ancho del botón
            height: '40px', // Alto del botón
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px', // Bordes redondeados
        };

        const ida = {
            zIndex: 1,
            position: 'absolute',
            top: 650,
            left: 20,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Color blanco transparentoso
            color: 'white',
            fontSize: '18px',
            width: '200px', // Ancho del botón
            height: '40px', // Alto del botón
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px', // Bordes redondeados
        };




        return (

            <>
                <div className="banner" style={bannerStyle}>

                    <div style={window.innerWidth <= 767 ? { width: '100%', height: '40px' } : fondazulStyle} >

                    </div>
                    <PrevArrow onClick={this.prevSlide} />

                    <NextArrow onClick={this.nextSlide} />
                    <Menu currentColor={currentColor} />


                    <div className="logo-and-svg-container">
                        <img src={Logo} alt="Logo" className="logo-img" />
                    </div>




                    <div className="buttons-container">
                        <a href="#/Ofertas" style={{ marginBottom: '10px' }}>
                            <div style={descubreButtonStyle}>
                                Descubre
                            </div>
                        </a>
                        <a href="#/nosotros" style={window.innerWidth <= 767 ? { marginBottom: '10px' } : null}>
                            <div style={window.innerWidth <= 767 ? { display: 'none' } : conoceMasButtonStyle}>
                                Conoce más
                            </div>
                        </a>
                    </div>



                    {/* <div className="CustomSVG-and-svg-container">
                        {this.state.showRegistroInicioSesionForm && <RegistroInicioSesionForm />}
                        <CustomSVG color={currentColor} text="Registro/Inicio de session" width="120" height="50" onClick={this.handleCustomSVGClick} />
                    </div> */}


                    {/* <div className='textPinkStyle' style={{
                        position: 'absolute',
                        top: 200,
                        left: 0,
                        width: '50%', // La mitad de la pantalla
                        height: '50%',
                        zIndex: 1,
                        fontSize: '42px',
                        color: 'white',
                        fontWeight: 'bold'
                    }}> */}
                    <div className='textPinkStyle' style={window.innerWidth <= 767 ? {
                        position: 'absolute',
                        top: 200,
                        left: 30,
                        width: '60%', // La mitad de la pantalla
                        height: '60%',
                        zIndex: 1,
                        fontSize: '',
                        color: 'white',
                        fontWeight: 'bold'

                    } : {
                        position: 'absolute',
                        top: 200,
                        left: 0,
                        width: '50%', // La mitad de la pantalla
                        height: '50%',
                        zIndex: 1,
                        fontSize: '42px',
                        color: 'white',
                        fontWeight: 'bold'
                    }}>



                        <img src={Textblu} alt="Logo" className="text-img" style={window.innerWidth <= 767 ? { width: '144%', height: '200px' } : {}}></img>

                    </div>



                    <div className="Redes-and-svg-container">

                        <img
                            src={redes}
                            alt="Mi SVG"
                            className="svg-image"
                            style={{ width: '65px', height: '500px' }} // Ajusta el tamaño aquí
                        />

                    </div>
                    <div className="Redes-whatsaap-and-svg-container">
                        <a href="https://api.whatsapp.com/send?phone=14078006570&text=Hola%20quiero%20saber%20mas%20de%20planes%turísticos%20" target="_blank" rel="noopener noreferrer">
                            <img
                                src={whatsaap}
                                alt="WhatsApp"
                                className="svg-image"
                                style={{ width: '45px', height: '480px' }} // Ajusta el tamaño aquí
                            />
                        </a>
                    </div>
                    <div className="Redes-instagram-and-svg-container">
                        <a href="https://instagram.com/agatours_promociones?igshid=MWZjMTM2ODFkZg==" target="_blank" rel="noopener noreferrer">
                            <img
                                src={instagram}
                                alt="Instagram"
                                className="svg-image"
                                style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                            />
                        </a>
                    </div>
                    <div>


                        <ApiCaller>
                            {(ciudadesData) => {
                                // console.log("este es el array que trae ", ciudadesData); // Agrega esto para verificar los datos
                                return (
                                    <div>
                                        {ciudadesData.map((ciudad) => (
                                            <div key={ciudad.Id}>
                                                <p>{ciudad.Nombre}</p>
                                            </div>
                                        ))}
                                    </div>
                                );
                            }}
                        </ApiCaller>

                    </div>

                    <div className="Redes-facebook-and-svg-container">
                        <a href="https://www.facebook.com/agatourspromociones" target="_blank" rel="noopener noreferrer">

                            <img
                                src={facebook}
                                alt="https://www.facebook.com/agatourspromociones"
                                className="svg-image"
                                style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                            />
                        </a>
                    </div>


                </div>

                <div className="footer-container">
                    <div className="footer-content">
                        <div className="footer-section">
                            <h4>Quiénes Somos</h4>
                            <Link to="/nosotros"> <p>Información sobre la agencia y sus valores.</p></Link>
                        </div>


                        <div className="footer-section">
                            <h4>Política de Cancelaciones</h4>
                            <Link to="/Condiciones">   <p>Detalles sobre la política de cancelaciones.</p></Link>
                        </div>


                        <div className="footer-section">
                            <h4>Términos y Condiciones Generales</h4>
                            <Link to="/Condicione">  <p>Condiciones bajo las cuales se ofrecen los servicios.</p></Link>
                        </div>

                    </div>
                    <div className="copyright">
                        <p>&copy; 2023 AGATOURS. Todos los derechos reservados.</p>
                    </div>
                </div>


            </>
        );
    }
}

export default Banner;
