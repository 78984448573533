import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menunosotros';
import './Condic.css';
import 'bootstrap/dist/css/bootstrap.css';
import Logo from "../../Img/Logo_1.png";
import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import redes from '../../Img/Barraredessociales.svg';
import whatsaap from '../../Img/Redes/wpp.svg';
import instagram from '../../Img/Redes/ig.svg';
import facebook from '../../Img/Redes/fb.svg';

function Condiciones() {
    const [destinos, setDestinos] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');


    useEffect(() => {
        fetch('https://aga.gst-panel.com/ap.php')
            .then((response) => response.json())
            .then((data) => {
                setDestinos(data);
            })
            .catch((error) => {
                console.error('Error al obtener datos de la API:', error);
            });
    }, []);

    const handleDownloadExcel = () => {
        // Crea un enlace temporal
        const link = document.createElement('a');
        link.href = '/fronteras.xls';  // Ruta al archivo Excel

        link.download = 'fronteras.xlsx';  // Nombre del archivo a descargar
        document.body.appendChild(link);

        // Simula un clic en el enlace para iniciar la descarga
        link.click();

        // Limpia el enlace temporal
        document.body.removeChild(link);
        console.log("este es el archivo ")
    };

    const handleWhatsAppClick = () => {
        // Mostrar el formulario al hacer clic en el botón
        setShowForm(true);
    };

    const handleFormSubmit = () => {
        // Construir el mensaje con la información del formulario
        const mensajeWhatsApp = `Hola, mi nombre es ${nombre} y mi correo es ${correo}. ${mensaje}`;

        // Crear la URL de WhatsApp con el mensaje prellenado
        const linkWhatsApp = `https://wa.me/16892864404?text=${encodeURIComponent(mensajeWhatsApp)}`;

        // Abrir una nueva ventana para iniciar el chat de WhatsApp
        window.open(linkWhatsApp, '_blank');

        // Ocultar el formulario después de enviar el mensaje
        setShowForm(false);
    };



    return (
        <div>

            <Menu />

            <div className="logo-and-svg-containers">
                <img src={Logo} alt="Logo" className="logo-img" />
            </div>

            <div className="container">
                <h1 className="mt-5 mb-4">Cancelaciones  </h1>

                <div className="condiciones-container">
                    <div className="condiciones-section">
                        <h2>Parte I: Condiciones de Cancelaciones (Versión Español)</h2>
                        {/* Contenido de la Parte I aquí */}
                        POLITICA DE REEMBOLSOS Y CREDITOS
                        Nota: estos gastos son aplicables solo para reservas individuales (las reservas de grupo tienen su propia regulación que es enviada con su cotización). Estos gastos aplican para la porción del tour terrestre solamente y no aplican cuando hay otros servicios tales como vuelos, cruceros, billetes de eventos, tren y otros servicios similares los cuales pueden tener otras regulaciones
                        Responsabilidad del pasajero:
                        Ud. debe llevar un pasaporte valido como mínimo hasta 6 meses después de la fecha de salida del viaje. Es su responsabilidad averiguar si necesita vacuna, PCR o antigeno y si estas tienen la validez necesaria según el pais o los paises a donde viajará, así mismo cualquier otro formato de salud o documentación. También deberá averiguar y tramitar visas y asegurarse de llevarlas consigo, llegar a tiempo a los aeropuertos y asegurarse de llevar un seguro de viaje y salud. De no comprar un seguro, el viajero es totalmente responsable por las siguientes penalidades de la parte terrestre de su viaje
                        (las aerolíneas y cruceros tienen diferentes restricciones).
                        Condiciones:
                        DEPOSITOS
                        Deposito requerido para confirmar una reserva: $100.00 por persona
                        Depósitos adicionales no son requeridos hasta 62 días antes de la fecha de salida, pero el cliente puede si lo desea hacer depósitos adicionales teniendo en cuenta la política de cancelación mencionado abajo.
                        Pago final requerido 61 días antes de la fecha de salida.
                        CANCELACIONES:
                        Las cancelaciones deben de ser recibidas por escrito via correo electrónico y usted debe haber recibido un correo de confirmación de que hemos recibido su pedido de cancelacion.
                        Todas las reservas de tours podrán ser canceladas con mínimo fee hasta 35 días antes de la fecha de viaje (salvo excepciones previamente mencionadas en su reserva). Una cancelación no implica un reembolso o crédito automático. Ver políticas de reembolsos y/o créditos para más información.
                        Nota: estos gastos son aplicables solo para reservas individuales (las reservas de grupo tienen su propia regulación que es enviada con su cotización). Estos gastos aplican para la porción del tour terrestre solamente y no para otros servicios como entradas, eventos o tours de un día compradas adicionalmente a su tour original. Tampoco son válidas para vuelos, cruceros y trenes los cuales pueden tener condiciones de cancelación diferentes. Excepción: En el caso de que su viaje particularmente tuviera algún otro tipo de regulación en cuanto a gastos de cancelación ya sea porque son fechas o servicios especiales, se aplicara la condición más restringida.
                        En caso de que haya gastos por estos servicios adicionales, se le sumara a las penalidades mencionados abajo sea de créditos o rembolsos.
                        Cancelaciones con 60 días de anticipación o más de la fecha de viaje
                        <br></br>
                        1.	Podrá obtener un crédito por el valor total de su depósito (menos $35.00 por persona por gastos administrativos) para un próximo viaje.
                        <br></br>2.	Podrá obtener un rembolso por el valor de su depósito menos $200.00 por persona como gastos administrativos de cancelacion y adicionalmente se sumara cualquier otro gasto bancario que su depósito pudo haber originado.
                        •	Es sumamente importante que usted compre un seguro de viaje dentro de los 7 dias de dejar el depósito para proteger su inversión y su salud.
                        Cancelaciones entre 59 días hasta 35 días antes de la fecha de viaje:
                        <br></br>1.	Podrá obtener un crédito por el valor total de su depósito (s) menos $75.00 por persona por gastos administrativos por cambios, para un próximo viaje.
                        <br></br>2.	Podra obtener un rembolso por el valor de su depósito menos el 15% por persona del valor del tour o mínimo $200.00 por persona como gastos administrativos de cancelación el que sea mas alto de los dos, más cualquier gasto bancario que su depósito pudo haber originado.
                        •	Es sumamente importante que usted compre un seguro de viaje dentro de los 7 dias de dejar el depósito para proteger su inversión y su salud.
                        Cancelaciones dentro de los 34 días antes de la fecha de viaje:
                        <br></br>1.	Podrá solicitar un crédito. Festival cobrará 15% por persona del valor del tour pero no menos a $200 por persona, adicionalmente a las gastos bancarios generados de la reserva y las penalidades de nuestros proveedores en destino de acuerdo a la tabla abajo mencionada:
                        (El monto de 15% por cargos de servicio será usado para cubrir parcialmente los costos de nuestro asesoramiento profesional y nuestros gastos operativos, adicionalmente tendrá gastos de cancelación de nuestros proveedores según la siguiente tabla y siempre y cuando nos haya enviado un correo electrónico desistiendo del viaje dentro de los días y horarios de trabajo y de que nosotros le hayamos contestado un correo de recibimiento de este).
                        Aquí la tabla de cancelaciones adicionales:<br></br>
                        <br></br>•	El 10% del valor total del viaje si el desistimiento se produce entre 30-35 días de antelación a la fecha del comienzo del viaje.
                        <br></br> •	El 20% del valor total del viaje si el desistimiento se produce entre 25-29 días de antelación a la fecha del comienzo del viaje.
                        <br></br> •	El 30% del valor total del viaje si el desistimiento se produce entre 19-24 días de antelación a la fecha del comienzo del viaje.
                        <br></br> •	El 100% del valor del viaje si el desistimiento se produce dentro los 18 días antes de la fecha de comienzo del viaje o si no lo cancelara.
                        <br></br><br></br>              Nota de reflexión:
                        <br></br><br></br>
                        Les rogamos comprender que estos costos de cancelación solo compensan el trabajo que todas las personas que organizamos su viaje, tiempo que hemos invertido a través de los días, semanas y a veces meses de coordinación, las personas envueltas en su viaje pueden ser: empleados de reservas de hoteles, de tours y transporte. Separaciones de días de trabajo para sus guías, autos y choferes y poder garantizar su viaje. Gastos bancarios por cargar su tarjeta de crédito y gastos bancarios para enviar su dinero como transferencia internacional. También el tiempo invertido por nosotros sus servidores de Aga Tours. Estos gastos se generan cuando Usted. cancela un viaje sea por el motivo que fuere incluyendo motivos médicos, pandemias desastres naturales e impedimentos políticos. Todos los motivos de fuerza mayor en los que tanto Usted. como nosotros nos vemos perjudicados por lo que no se podrán hacer excepciones por cancelaciones de fuerza mayor. Es por eso por lo que recomendamos tanto que compre un seguro de viaje, es la opción que todo viajero necesita para cubrir su inversión. Respetamos si Ud. decide no invertir en un seguro, pero si le rogamos que llegado el momento de una cancelación acepte los términos responsablemente. Ayúdenos con una clara y amistosa comunicación llegado de momento de una cancelación.
                        Les pedimos por lo tanto comprender y respetar nuestras políticas temporales con mayor flexibilidad y así seguir sirviéndole a un mínimo costo operativo. Con su apoyo y comprensión en nombre de Aga Tours y de todos nuestros compañeros en el rubro del turismo en el mundo para así poder seguir trabajando y hacer sus sueños realidad.
                        La Gerencia.AGA TOUR
                        <br></br>

                    </div>

                    <div className="condiciones-section">
                        <h2>Parte II: Conditions for Cancelations (English Version)</h2>
                        {/* Contenido de la Parte II aquí */}
                        Cancellations policy  <br></br>
                        POLICY OF REFUNDS AND CREDITS
                        Note: These expenses apply only to individual bookings (group bookings have their own regulation that is sent with your quote). These expenses apply to the ground tour part only and do not apply when there are other services such as flights, cruises, event tickets, train and other similar services which may have other regulations.
                        Passenger Responsibility:<br></br><br></br>
                        You must carry a valid passport at least up to 6 months after the date of departure of the trip. You will also need to find out and process visas or vaccinations and make sure you take them with you, get to airports on time, and make sure you carry travel and health insurance. If you do not purchase insurance, the traveler is fully responsible for the following penalties on the land side of your trip
                        (Airlines and cruise ships have different restrictions).
                        Conditions:<br></br><br></br>
                        Deposits<br></br><br></br>
                        Deposit required to confirm a booking: $100.00 per person
                        Additional deposits are not required up to 62 days before the date of departure, but the customer may wish to make additional deposits considering the cancellation policy mentioned below.
                        Final payment required 61 days before departure date.
                        Cancellations:<br></br>
                        Cancellations must be received in writing via email and you must have received a confirmation email that we have received your cancellation request. All tour reservations may be canceled with a minimum fee up to 35 days before the travel date (except for exceptions previously mentioned in your reservation). A cancellation does not imply an automatic refund or credit. See refund and / or credit policies for more information.
                        <br></br><br></br>Note: These expenses apply only to individual bookings (group bookings have their own regulation that is sent with your quote). These expenses apply to the ground tour part only and do not apply when there are other services such as flights, cruises, event tickets, train and other similar services which may have other regulations. Exception: In the event that your trip particularly has some other type of regulation regarding cancellation costs, either because they are special dates or services, the most restricted condition for penalties and fees will apply.
                        In the event there are penalties and fees for these types of cancelations they will be added to the below fees and penalties mentioned below either for credits or refunds.
                        Cancellations 60 days in advance or more from departure date:<br></br><br></br>
                        1.	You can get a full credit for the total value of your deposit(s) (minus $35.00 per person administration fee) for an upcoming trip.
                        <br></br>2.	You may request a refund for your deposit (s) minus $200.00 per person for administration fee in addition to any additional bank fees your deposits may have originated.
                        <br></br>•	It is very important that you purchase a travel insurance policy with in the first 7 days of leaving a deposit to cover your investment and health for your tour.
                        Cancellations between 59 days up to 35 days before the date of travel:
                        <br></br><br></br>1.	You can get a full credit for the total value of your deposit(s) (minus $75.00 per person administration fee) for an upcoming trip.
                        <br></br><br></br>2.	You may request a refund for your deposit (s) minus 15% of the value of the tour per person and no less than $200.00 per person for administration fee, whichever is highest, in addition to any additional bank fees your deposits may have originated.
                        <br></br><br></br>•	It is very important that you purchase a travel insurance policy with in the first 7 days of leaving a deposit to cover your investment and health for your tour.
                        Cancellations within 34 days before the date of travel:
                        <br></br><br></br>1.	You may request a credit and Festival will charge 15% or $200.00 per person which ever is higher in addition to any bank fees and penalties of our suppliers at destination according to the table below:
                        (The 15% per person, no less than $200.00 per person of service charges will be used to partially cover the costs associated with professional advice and operating costs, additionally you will have cancellation fees from our suppliers according to the table below and as long as you have sent us an email from the trip within the days and hours of work and that we have answered a receipt email from you).
                        Here is the table of additional cancellations:
                        <br></br><br></br>•	10% of the total value of the trip if the withdrawal occurs between 30-35 days before the date of the start of the trip.
                        <br></br><br></br>•	20% of the total value of the trip if the withdrawal occurs between 25-29 days before the date of the start of the trip.
                        <br></br><br></br>•	30% of the total value of the trip if the withdrawal occurs between 19-24 days before the date of the start of the trip.
                        <br></br><br></br>•	100% of the value of the trip and withdrawal occurs within 18 days before the start date of the trip or if you do not cancel it.
                        <br></br><br></br>Reflection Note:<br></br><br></br>
                        Please understand that these cancellation costs only compensate for the work that all the people who organize your trip, time we have invested through the days, weeks and sometimes months of coordination, the people involved in your trip can be employees of hotel reservations, tours and transportation. Separations of working days for your guides, cars and drivers and being able to guarantee your trip. Bank charges for charging your credit card and bank charges to send your money as an international transfer. Also, the time spent by us your Aga Tours Operators agents. These cancellation percentages do not cover our commission which we lose when you cancel a trip for whatever reason including medical reasons, natural disaster pandemics and political impediments. All the reasons for force majeure in which both you and we are victims. That is why we highly recommend buying travel insurance, it is the option every traveler needs to cover their investment. We respect if you choose not to invest in insurance, but if we ask you to accept the terms responsibly at the time of cancellation. Help us with clear and friendly communication at the time of a cancellation.
                        We therefore ask you to understand and respect our temporary policies more flexibly and thus continue to serve you at a minimum operating cost. With your support and understanding on behalf of Aga Tours and all our colleagues in the field of tourism in the world so that you can continue working and make your dreams come true.
                        AGA TOUR


                    </div>

                    {/* Resto del código... */}

                </div>
                <div className="Redes-and-svg-container">
                    <img
                        src={redes}
                        alt="Mi SVG"
                        className="svg-image"
                        style={{ width: '65px', height: '500px' }} // Ajusta el tamaño aquí
                    />

                </div>
                <div className="Redes-whatsaap-and-svg-container">
                    <a href="https://api.whatsapp.com/send?phone=16892864404&text=🖐%EF%B8%8FHola%20!%20quiero%20saber%20más%20!" target="_blank" rel="noopener noreferrer">
                        <img
                            src={whatsaap}
                            alt="WhatsApp"
                            className="svg-image"
                            style={{ width: '45px', height: '480px' }} // Ajusta el tamaño aquí
                        />
                    </a>
                </div>
                <div className="Redes-instagram-and-svg-container">
                    <a href="https://instagram.com/agatours_promociones?igshid=MWZjMTM2ODFkZg==" target="_blank" rel="noopener noreferrer">
                        <img
                            src={instagram}
                            alt="Instagram"
                            className="svg-image"
                            style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                        />
                    </a>
                </div>
                <div>

                    <div className="Redes-facebook-and-svg-container">
                        <a href="URL_DEL_FACEBOOK" target="_blank" rel="noopener noreferrer">
                            <img
                                src={facebook}
                                alt="https://www.facebook.com/agatourspromociones"
                                className="svg-image"
                                style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                            />
                        </a>
                    </div>
                </div>
            </div>


            <button className="whatsapp-button" onClick={handleWhatsAppClick}>
                {/* Tu icono o texto del botón... */}<FaWhatsapp size={30} color="#fff" />
            </button>

            {/* Formulario flotante */}
            {showForm && (
                <div className="formulario-whatsapp">
                    <span className="close-button" onClick={() => setShowForm(false)}>
                        &times;
                    </span>
                    <h2>Comunicate con nosotros</h2>
                    <label>Nombre:</label>
                    <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                    <label>Correo:</label>
                    <input type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} required />
                    <label>Mensaje:</label>
                    <textarea value={mensaje} onChange={(e) => setMensaje(e.target.value)} required />
                    <button onClick={handleFormSubmit}>Enviar a WhatsApp</button>
                </div>
            )}
            {/* <Link to="https://api.whatsapp.com/send?phone=16892864404&text=🖐%EF%B8%8FHola%20!%20quiero%20saber%20más%20!" target="_blank" className="whatsapp-button">
                <FaWhatsapp size={30} color="#fff" />
            </Link> */}
        </div>
    );
}

export default Condiciones;
