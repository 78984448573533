import React, { useState, useEffect } from 'react';

function Createinfo({ onSubmitDestino, onSubmitOferta }) {
    const [turs, setTurs] = useState([]);
    const [formMessage, setFormMessage] = useState(null);

    const [formDataOferta, setFormDataOferta] = useState({
        Id_destino: '',
        itinerario: '',
        incluye: '',
        noincluye: '',
        adicionales: '',
        itinerarioi: '',
        incluyei: '',
        noincluyei: '',
        adicionalesi: '',
    });

    useEffect(() => {
        fetch('https://aga.gst-panel.com/api_destinos_v.php')
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    setTurs(data);
                } else {
                    console.error('La respuesta del servidor está vacía.');
                }
            })
            .catch((error) => {
                console.error('Error al obtener la lista de turs:', error);
            });
    }, []);

    const handleChangeOferta = (e) => {
        const { name, value, type, files } = e.target;
        setFormDataOferta((prevData) => ({
            ...prevData,
            [name]: type === 'file' ? files[0] : value,
        }));
    };

    const handleSubmitOferta = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('Id_destino', formDataOferta.Id_destino);
        formDataToSend.append('itinerario', formDataOferta.itinerario);
        formDataToSend.append('incluye', formDataOferta.incluye);
        formDataToSend.append('noincluye', formDataOferta.noincluye);
        formDataToSend.append('adicionales', formDataOferta.adicionales);
        formDataToSend.append('itinerarioi', formDataOferta.itinerarioi);
        formDataToSend.append('incluyei', formDataOferta.incluyei);
        formDataToSend.append('noincluyei', formDataOferta.noincluyei);
        formDataToSend.append('adicionalesi', formDataOferta.adicionalesi);

        fetch('https://aga.gst-panel.com/api_crearinfo.php', {
            method: 'POST',
            body: formDataToSend,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    console.log('Oferta agregada con éxito:', response);
                    setFormMessage({ type: 'success', text: 'Oferta agregada con éxito' });
                    // Limpiar los campos después de un éxito
                    setFormDataOferta({
                        Id_destino: '',
                        itinerario: '',
                        incluye: '',
                        noincluye: '',
                        adicionales: '',
                        itinerarioi: '',
                        incluyei: '',
                        noincluyei: '',
                        adicionalesi: '',
                    });

                } else {
                    console.error('Error al agregar oferta:', response.error);
                    setFormMessage({ type: 'error', text: 'Error al agregar oferta' });
                }
            })
            .catch((error) => {
                console.error('Error al agregar oferta:', error);
                setFormMessage({ type: 'error', text: 'Error al agregar oferta' });
            });

        // Después de unos segundos, limpiar el mensaje
        setTimeout(() => {
            setFormMessage(null);
        }, 5000);
    };
    return (
        <div className="create-forms">
            <div className="create-form">
                <h2>Crear Oferta</h2>
                {formMessage && (
                    <div className={formMessage.type === 'success' ? 'success-message' : 'error-message'}>
                        {formMessage.text}
                    </div>
                )}
                <form onSubmit={handleSubmitOferta}>
                    <select name="Id_destino" onChange={handleChangeOferta}>
                        <option value="">Seleccionar Tur</option>
                        {turs.map((tur) => (
                            <option key={tur.Id} value={tur.Id}>
                                {tur.DescripcionE}
                            </option>
                        ))}
                    </select>
                    <label>
                        Escribe tu itinerario:
                        <textarea name="itinerario" onChange={handleChangeOferta} required />
                    </label>
                    <label>
                        Escribe tu incluye:
                        <textarea name="incluye" onChange={handleChangeOferta} required />         </label>
                    <label>
                        Escribe tu noincluye: <textarea name="noincluye" onChange={handleChangeOferta} required />         </label>
                    <label>
                        Escribe tu adicionales:  <textarea name="adicionales" onChange={handleChangeOferta} required />         </label>
                    <label>
                        Escribe tu itinerario:
                        <textarea name="itinerarioi" onChange={handleChangeOferta} required />
                    </label>
                    <label>
                        Escribe tu incluye ingles:
                        <textarea name="incluyei" onChange={handleChangeOferta} required />         </label>
                    <label>
                        Escribe tu noincluye ingles: <textarea name="noincluyei" onChange={handleChangeOferta} required />         </label>
                    <label>
                        Escribe tu adicionales ingles:  <textarea name="adicionalesi" onChange={handleChangeOferta} required />         </label>

                    <button type="submit">Guardar Oferta</button>
                </form>
            </div>
        </div>
    );
}

export default Createinfo;
