import React, { Component } from 'react';
import './Menu.css';
import Lineas from "../../Img/Menu1.svg";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuActive: false, // Inicialmente, el menú está inactivo
        };
    }

    toggleMenu = () => {
        this.setState((prevState) => ({
            menuActive: !prevState.menuActive,
        }));
    };

    render() {
        const { menuActive } = this.state;

        return (
            <div className="menu">


                <div className="menu-icon" onClick={this.toggleMenu}>
                    <div className="lineas-svg">
                        <img src={Lineas} alt="Lineas" width="40" height="40" />

                    </div>
                </div>


                <div className={`menu-items ${menuActive ? 'active' : ''}`}>
                    <a href="#">DESTINOS</a>
                    <a href="#/Ofertas">OFERTAS</a>
                    <a href="#/Apertura">APERTURA DE FRONTERAS</a>
                    <a href="#/nosotros">¿ QUIENES SOMOS ? </a>
                </div>





            </div>
        );
    }
}


export default Menu;
