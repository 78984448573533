import React from 'react';
import flecha from "../Img/flecha carru.svg";
const PrevArrow = ({ onClick }) => {
    return (
        <div className="arrow prev" onClick={onClick}>
            {/* Aquí puedes usar un icono o cualquier otro contenido para representar la flecha hacia atrás */}
        </div>
    );
};

export default PrevArrow;
