
import { BrowserRouter as Router, Route, Routes, BrowserRouter, HashRouter } from 'react-router-dom';
import Banner from './components/Banner';
import Nosotros from './page/Nosotros/Nosotros'; // Importa tu página "Nosotros"
import Destinos from './page/Destinos/Destinos';
import Login from './page/Login/Login';
import Administracion from './page/Adm/Administracion';
import CreateDestinoForm from './page/Destinos/CreateDestinoForm';
import Apertura from './page/Aperturadefronteras/Apertura';
import Ofertas from './page/Ofertas/Ofertas';
import Ofta from './page/Ofertas/Ofta';
import Creartc from './page/Createinfodform/Createinfo';
import CrearOfertas from './page/Ofertas/CrearOfertas';
import Condiciones from './page/Tcp/Condiciones';
import Destinocel from './components/Destinocel';
import Destinopais from './components/Destinopais';

function App() {
  return (

    <HashRouter>
      <Routes>
        <Route path="/" element={<Banner />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/Destinos" element={<Destinos />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/administracion" element={<Administracion />} /> {/* Nueva ruta */}
        <Route path="/Creardestino" element={<CreateDestinoForm />} /> {/* Nueva ruta */}
        <Route path="/Ofertas" element={<Ofertas />} /> {/* Nueva ruta */}
        <Route path="/Apertura" element={<Apertura />} /> {/* Nueva ruta */}
        <Route path="/Creartc" element={<Creartc />} /> {/* Nueva ruta */}
        <Route path="/Crearofertas" element={<CrearOfertas />} /> {/* Nueva ruta */}
        <Route path="/Condiciones" element={<Condiciones />} /> {/* Nueva ruta */}
        <Route path="/Ofta/:id" element={<Ofta />} />
        <Route path="/Destinopais/:id" element={<Destinopais />} />

        <Route path="/Destinocel" element={<Destinocel />} />

      </Routes>

    </HashRouter>

  );
}

export default App;
