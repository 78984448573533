import React, { useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menunosotros';
import fondoImg from '../../Img/quienessomos/fondo.jpg'; // Importa la imagen
import Logo from '../../Img/Logo_1.png';
import Mision from '../../Img/quienessomos/calle-casas-fachadas-escena-multicolor-ai-generativa.jpg.png';
import Vision from '../../Img/quienessomos/rascacielos-al-atardecer.jpg.png';
import redes from '../../Img/Barraredessociales.svg';
import whatsaap from '../../Img/Redes/wpp.svg';
import instagram from '../../Img/Redes/ig.svg';
import facebook from '../../Img/Redes/fb.svg';
import { FaWhatsapp } from 'react-icons/fa';

import './Nosotros.css';



function Nosotros() {
    const [showForm, setShowForm] = useState(false);
    const [showMisionPopup, setShowMisionPopup] = useState(false);
    const [showVisionPopup, setShowVisionPopup] = useState(false);

    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');



    const style = {
        backgroundImage: `url(${fondoImg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '150vh',
        alignItems: 'center', // Centra horizontalmente
        justifyContent: 'center', // Alinea en la parte inferior


    };

    const titleStyle = {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'bold',
        fontSize: '40px',
        color: 'white',
        textAlign: 'center',
        marginTop: '50px', // Ajusta la distancia desde la parte superior (valor 50px como ejemplo)
    };

    const paragraphStyle = {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '20px',
        color: 'white',
        position: 'absolute',
        textAlign: 'center', // Centra el texto horizontalmente
        marginTop: '20px', // Ajusta la distancia desde el título
    };
    const paragraphStylde = {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '20px',
        color: 'white',
        position: 'absolute',
        textAlign: 'left', // Centra el texto horizontalmente
        marginTop: '20px', // Ajusta la distancia desde el título
    };

    const boldText = {
        fontWeight: 'bold',
    };

    const handleWhatsAppClick = () => {
        // Mostrar el formulario al hacer clic en el botón
        setShowForm(true);
    };

    const handleFormSubmit = () => {
        // Construir el mensaje con la información del formulario
        const mensajeWhatsApp = `Hola, mi nombre es ${nombre} y mi correo es ${correo}. ${mensaje}`;

        // Crear la URL de WhatsApp con el mensaje prellenado
        const linkWhatsApp = `https://wa.me/16892864404?text=${encodeURIComponent(mensajeWhatsApp)}`;

        // Abrir una nueva ventana para iniciar el chat de WhatsApp
        window.open(linkWhatsApp, '_blank');

        // Ocultar el formulario después de enviar el mensaje
        setShowForm(false);
    };

    const handleMisionClick = () => {
        setShowMisionPopup(true);
    };

    const handleVisionClick = () => {
        setShowVisionPopup(true);
    };

    return (
        <div style={style}>
            <Menu />

            <div className="salto" >

            </div>
            <div className="logo-and-svg-container">
                <img src={Logo} alt="Logo" className="logo-img" />
            </div>
            <div>                    <br></br>

                <h1 className="custom-h1" style={titleStyle}>¿QUIÉNES SOMOS?</h1>                    <br></br>

                <p style={paragraphStylde}>
                    <br></br>
                    <br></br><br></br>

                    <span style={boldText}>¡Descubre el mundo con Aga Tours, tu pasaporte a la aventura!</span><br />
                    Sumérgete en un universo de destinos exóticos, experiencias inolvidables y viajes a medida diseñados para satisfacer tus sueños viajeros.<br /> Desde las playas de ensueño hasta las cumbres más altas, Aga Tours te lleva a lugares que solo habías imaginado en tus fantasías.<br /> ¿Estás listo para emprender un viaje que cambiará tu vida?
                    <br />
                    <span style={boldText}>¡Únete a nosotros y comienza a explorar el planeta con estilo y elegancia!</span>
                </p>

            </div>


            {/* Botones de Misión y Visión solo para dispositivos móviles */}

            <div className={window.innerWidth <= 767 ? "mobile-buttons" : "mobile-buttonsb"}>

                <button onClick={handleMisionClick}>Misión</button>

                <button onClick={handleVisionClick}>Visión</button>

            </div>


            {showMisionPopup && (


                <div className="popup">
                    <div className="popup-content">
                        <img src={Mision} alt="Misión" />
                        <h2>Misión</h2>
                        {/* Contenido de la Misión */}
                        <p>Nuestra mision en Agatours es inspirar y enriquecer la vida de las personas atraves de experiencias de viaje excepcionales nos dedicamos a ofrecer excursiones y tours unicos en todo el mundo brindando momentos memorables y promociones irresistibles.
                            Nuestro compromiso es proporcionar servicios de calidad, seguridad y comodidad para que cada viaje con nosotros sea una aventura inolvidable..</p>
                        <button onClick={() => setShowMisionPopup(false)}>Cerrar</button>
                    </div>
                </div>
            )}
            {showVisionPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <img src={Vision} alt="Visión" />
                        <h2>Visión</h2>
                        {/* Contenido de la Visión */}
                        <p>En Aga Tours, nuestra visión es convertirnos en el referente global de viajes y experiencias inolvidables. Queremos inspirar a las personas de todo el mundo a explorar, descubrir y sumergirse en la belleza y diversidad de nuestro planeta. Nos esforzamos por ser reconocidos como líderes en la industria del turismo, ofreciendo destinos exóticos y aventuras únicas que enriquezcan la vida de nuestros viajeros. Nos comprometemos a brindar un servicio excepcional, diseñando viajes a medida que superen las expectativas de nuestros clientes .</p>
                        <button onClick={() => setShowVisionPopup(false)}>Cerrar</button>
                    </div>
                </div>
            )}



            <div className="cards" style={{ top: '500px', left: '100px' }}>
                <img src={Mision} alt="Misión" />
                <center>                <h2>MISIÓN</h2> </center>

                <p>Nuestra mision en Agatours es inspirar y enriquecer la vida de las personas atraves de experiencias de viaje excepcionales nos dedicamos a ofrecer excursiones y tours unicos en todo el mundo brindando momentos memorables y promociones irresistibles.<br /> Nuestro compromiso es proporcionar servicios de calidad, seguridad y comodidad para que cada viaje con nosotros sea una aventura inolvidable.</p>
            </div>
            <div className="cards" style={{ top: '500px', left: '800px' }}>
                <img src={Vision} alt="Visión" />
                <center>
                    <h2>VISIÓN</h2>
                </center>
                <p>En Aga Tours, nuestra visión es convertirnos en el referente global de viajes y experiencias inolvidables. Queremos inspirar a las personas de todo el mundo a explorar, descubrir y sumergirse en la belleza y diversidad de nuestro planeta. Nos esforzamos por ser reconocidos como líderes en la industria del turismo, ofreciendo destinos exóticos y aventuras únicas que enriquezcan la vida de nuestros viajeros.

                    Nos comprometemos a brindar un servicio excepcional, diseñando viajes a medida que superen las expectativas de nuestros clientes .</p>


            </div>

            <div className="Redes-and-svg-container">
                <img
                    src={redes}
                    alt="Mi SVG"
                    className="svg-image"
                    style={{ width: '65px', height: '500px' }} // Ajusta el tamaño aquí
                />

            </div>
            <div className="Redes-whatsaap-and-svg-container">
                <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                    <img
                        src={whatsaap}
                        alt="WhatsApp"
                        className="svg-image"
                        style={{ width: '45px', height: '480px' }} // Ajusta el tamaño aquí
                    />
                </a>
            </div>
            <div className="Redes-instagram-and-svg-container">
                <a href="https://instagram.com/agatours_promociones?igshid=MWZjMTM2ODFkZg==" target="_blank" rel="noopener noreferrer">
                    <img
                        src={instagram}
                        alt="Instagram"
                        className="svg-image"
                        style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                    />
                </a>
            </div>
            <div>

                <div className="Redes-facebook-and-svg-container">
                    <a href="URL_DEL_FACEBOOK" target="_blank" rel="noopener noreferrer">
                        <img
                            src={facebook}
                            alt="https://www.facebook.com/agatourspromociones"
                            className="svg-image"
                            style={{ width: '49px', height: '40px' }} // Ajusta el tamaño aquí
                        />
                    </a>
                </div>
            </div>
            <button className="whatsapp-button" onClick={handleWhatsAppClick}>
                {/* Tu icono o texto del botón... */}<FaWhatsapp size={30} color="#fff" />
            </button>

            {/* Formulario flotante */}
            {showForm && (
                <div className="formulario-whatsapp">
                    <span className="close-button" onClick={() => setShowForm(false)}>
                        &times;
                    </span>
                    <h2>Comunicate con nosotros</h2>
                    <label>Nombre:</label>
                    <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                    <label>Correo:</label>
                    <input type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} required />
                    <label>Mensaje:</label>
                    <textarea value={mensaje} onChange={(e) => setMensaje(e.target.value)} required />
                    <button onClick={handleFormSubmit}>Enviar a WhatsApp</button>
                </div>
            )}
        </div>
    );
}

export default Nosotros;
