import React, { useState } from 'react';

function CreateForm({ title, onSubmit, onClose, countries, formType }) {
    const [formData, setFormData] = useState({
        agencia: '',
        valor: '',
        descripcionE: '',
        descripcionI: '',
        pais: '',
        descripcion: '', // Campo adicional para descripción del país
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formType === 'country') {
            // Código para guardar un país
            onSubmit(formData);
        } else if (formType === 'tour') {
            // Código para guardar un tour
            onSubmit(formData);
        }
    };

    return (
        <div className="create-form">
            <h2>{title}</h2>
            <form onSubmit={handleSubmit}>
                {formType === 'tour' && (
                    <div>
                        <input type="text" name="agencia" placeholder="Agencia" onChange={handleChange} required />
                        <input type="number" name="valor" placeholder="Valor" onChange={handleChange} required />
                        <input type="text" name="descripcionE" placeholder="DescripcionE" onChange={handleChange} required />
                        <input type="text" name="descripcionI" placeholder="DescripcionI" onChange={handleChange} required />
                        <select name="pais" onChange={handleChange}>
                            <option value="">Seleccionar País</option>
                            {countries.map((country) => (
                                <option key={country.Id} value={country.Id}>
                                    {country.Descripcion}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {formType === 'country' && (
                    <div>
                        <input type="text" name="descripcion" placeholder="Descripción del País" onChange={handleChange} />
                        <select name="continente" onChange={handleChange}>
                            <option value="">Seleccionar Continente </option>
                            {countries.map((country) => (
                                <option key={country.Id} value={country.Id}>
                                    {country.Descripcion}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <button type="submit">{formType === 'country' ? 'Guardar País' : 'Guardar Tour'}</button>
                <button type="button" onClick={onClose}>Cancelar</button>
            </form>
        </div>
    );
}

export default CreateForm;
